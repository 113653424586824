import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../styles/components.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export const AutoSwiper = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide><img src={require(`../../images/julio1.jpeg`)} /></SwiperSlide>
                <SwiperSlide><img src={require(`../../images/julio2.jpeg`)} /></SwiperSlide>
                <SwiperSlide><img src={require(`../../images/julio3.jpeg`)} /></SwiperSlide>
                <SwiperSlide><img src={require(`../../images/julio4.jpeg`)} /></SwiperSlide>
                <SwiperSlide><img src={require(`../../images/julio5.jpeg`)} /></SwiperSlide>
                <SwiperSlide><img src={require(`../../images/julio6.jpeg`)} /></SwiperSlide>
            </Swiper>
        </>
    );
}