import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { signup } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const Signup = ({
 	signup,
 	loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [accountCreated, setAccountCreated] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [rePasswordType, setRePasswordType] = useState('password');
	const [reIconShow, setReIconShow] = useState(false);
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		re_password: ''
	});

	const {
		first_name,
		last_name,
		email,
		password,
		re_password
	} = formData;

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	signup(first_name, last_name, email, password, re_password);
    	setAccountCreated(true);
    	window.scrollTo(0,0)
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

  	const handleShowRePassword = () => {
  		if (rePasswordType === 'password') {
  			setRePasswordType('text')
  			setReIconShow(!reIconShow)
  		}
  		else if (rePasswordType === 'text') {
  			setRePasswordType('password')
  			setReIconShow(!reIconShow)
  		}	
  	}

	if (accountCreated && !loading)
		return <Navigate to='/signup-success' />;

	return (
		<FullWidthLayout>
			<div className='container-login'>
				<div className='container-register-form'>
					<span className='border-line-1'></span>
					<form className='form-content' onSubmit={e => onSubmit(e)}>
						<div className='contain-form'>
							<h3 className='form-title'>Registro</h3>
							<div className='input-contain'>
								<input
									name='first_name'
									value={first_name}
									onChange={e => onChange(e)}
									type='text'
									required
									className='input-form'
								/>
								<span htmlFor='first_name'>Nombre</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='last_name'
									value={last_name}
									onChange={e => onChange(e)}
									type='text'
									required
									className='input-form'
								/>
								<span htmlFor='last_name'>Apellido</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='email'
									value={email}
									onChange={e => onChange(e)}
									type='email'
									required
									className='input-form' 
								/>
								<span htmlFor='email'>Correo Electrónico</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='password'
									value={password}
									onChange={e => onChange(e)}
									type={passwordType}
									required
									className='input-form'
								/>
								{iconShow ? (
									<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
								) : (
									<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
								)}
								<span htmlFor='password'>Contraseña</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='re_password'
									value={re_password}
									onChange={e => onChange(e)}
									type={rePasswordType}
									required
									className='input-form'
								/>
								{reIconShow ? (
									<FaRegEyeSlash onClick={handleShowRePassword} className='password-show-hide' />
								) : (
									<FaRegEye onClick={handleShowRePassword} className='password-show-hide' />
								)}
								<span htmlFor='re_password'>Repita la contraseña</span>
								<i></i>
							</div>
							{loading ? 
								<button type='button' className='button-form'><Oval
			  						visible={true}
									height="20"
									width="20"
									color="#222"
									ariaLabel="oval-loading"
									wrapperStyle={{}}
									wrapperClass=""
									/>
								</button> : 
								<button type='submit' className='button-form'>
									Registrarse	
								</button>
							}
							<div>
								<p className='change-login-signup'>¿Ya tienes una cuenta?<Link to='/login' className='link-change' style={{textDecoration: 'none'}}>Inicia sesión</Link></p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</FullWidthLayout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	signup
}) (Signup)