import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { useState, useEffect } from 'react';
import { signup } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';


const SignupSuccess = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<FullWidthLayout>
			<div className='container-login'>
				<div className='container-signup-success'>
					<h3>¡Felicidades, tu cuenta ha sido creada con exito!</h3>
				</div>
			</div>
		</FullWidthLayout>
	);
}

export default SignupSuccess