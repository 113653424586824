import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import '../../styles/components.css';
import {
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaPinterest,
	FaTelegramPlane,
	FaYoutube,
} from "react-icons/fa";


export const ContactForm = () => {

	const form = useRef();
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_mpalr4a', 'template_3pslp3t', form.current, {
                publicKey: 'nfd_phSCzilgjQfDr',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

	return (
		<div className='container container-contact-form'>
			<form className='contact-form' ref={form} onSubmit={sendEmail}>
            	<div className='title-contact'>
		            <h3></h3>
		            <p></p>
		        </div>
		        <div className='contain-contact-form'>
		           	<div className='container-contact-divider'>
		                <div className='input-contact'>
		                    <input
		                    	type='text'
		                    	name='user_name'
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <span>Nombre</span>
		                    <i></i>
		                </div>
		                <div className='input-contact'>
		                    <input
		                    	type='email'
		                    	name='user_email'
		                    	className='input-contact-form'
		                    	required
		                    />
		                    <span>Email</span>
		                    <i></i>
		                </div>
		            </div>
		            <div className='container-contact-divider'>
		                <div className='input-contact'>
		                    <input 
		                    	type='text' 
		                    	name='subject' 
		                    	className='input-contact-form' 
		                    	required 
		                    />
		                    <span>Asunto</span>
		                    <i></i>
		                </div>
		                <div className='input-contact'>
		                    <input
									name='phone'
									type='text'
									className='input-form'
									required
								/>
							<span>Teléfono</span>
							<i></i>
		                </div>
		            </div>
		            <div className='input-contact-msg'>
		                <textarea name='message' className='input-contact-form' required></textarea>
		                <span>Mensaje</span>
		                <i></i>
		            </div>
		            <p className='required-fields'>Todos los campos son obligatorios</p>
		            <div className='container-submit-button'>
		            	{sendedEmail ? (
		                	<p className='submited-contact-button'>Enviado</p>
		            	) : (
		                	<input type='submit' value='Enviar' className='submit-contact-button' />
		            	)}
		            </div>
		        </div>
		    </form>
		    <div className='container-info-contact'>
		    	<div className='contain-contact-paragraph'>
					<h3>Comunícate conmigo</h3>
					<p>Si te gustaria conocer más, acerca de las promociones, o quieres unirte a mi comunidad, 
					comunícate conmigo a través de los diferentes medios</p>
				</div>
				<div className='contain-info-contact'>
					<p>Número de Whatsapp</p>
					<Link to='https://wa.me/+543574405530?text=Hola,%20me%20interesa%20comprar%20una%20Essen.' className='info-contact-link' style={{textDecoration: 'none'}}>+54 9 3574-405530</Link>
				</div>
				<div className='contain-info-contact'>
					<p>Email</p>
					<Link to='mailto:brendaa3060@gmail.com' className='info-contact-link' style={{textDecoration: 'none'}}>brendaa3060@gmail.com</Link>
				</div>
				<div className='contain-info-contact'>
					<p>Unete a mi comunidad</p>
					<Link to='/' className='info-contact-link' style={{textDecoration: 'none'}}>Comunidad de telegram</Link>
				</div>
				<div className='container-contact-social'>
					<Link to='https://www.facebook.com/brenda.ledesma.986?locale=es_LA' target='_blank' className='social-contact-links facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
					<Link to='https://www.instagram.com/brenledesmats/' target='_blank' className='social-contact-links instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
					<Link to='https://www.pinterest.es/bledesma0111/' target='_blank' className='social-contact-links pinterest' style={{textDecoration: 'none'}}><FaPinterest /></Link>
					<Link to='/' target='_blank' className='social-contact-links telegram' style={{textDecoration: 'none'}}><FaTelegramPlane /></Link>
					<Link to='/' target='_blank' className='social-contact-links youtube' style={{textDecoration: 'none'}}><FaYoutube /></Link>
				</div>
			</div>
		</div>
	)
};