import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PiMagnifyingGlass } from "react-icons/pi";
import { IoMdHeart } from "react-icons/io";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router'
import '../../styles/blogs.css';
import { get_categories } from '../../redux/actions/categories';
import { get_recipe_reviews, get_recipe_review, create_recipe_review, update_recipe_review, delete_recipe_review, filter_recipe_reviews } from '../../redux/actions/reviews';
import { get_blog_list, get_blog, search_blog,} from '../../redux/actions/blog';
import WrapperBlog from '../../components/blog/WrapperBlog';
import { add_wishlist_blog_item, get_wishlist_blog_items, get_wishlist_blog_item_total } from '../../redux/actions/wishlist';


const BlogDetail = ({
	isAuthenticated,
	get_blog_list,
	search_blog,
	blog_list,
	get_categories,
	categories,
	get_blog,
	post,
	add_wishlist_blog_item,
	get_wishlist_blog_items,
	get_wishlist_blog_item_total,
	blogItems,
}) => {

	const params = useParams();
	const slug = params.slug;
	const currentUrl = window.location.href;
	const [search, setSearch] = useState('');
	const [goToSearch, setGoToSearch] = useState(false);
	const [favoriteBlog, setFavoriteBlog] = useState(false);
	const [datePublishPost, setDatePublishPost] = useState(null);

	useEffect(() => {
		window.scrollTo(0,0)
		get_blog(slug);
		get_categories();
		get_wishlist_blog_items();
	}, [slug]);

	const addToWishlist = async () => {
		if (!isAuthenticated) {
			return <Navigate to='/login' />
		} else if (
			post && 
			post !== null &&
			post !== undefined
		) {
			await add_wishlist_blog_item(post.blog_uuid);
			await get_wishlist_blog_items();
			await get_wishlist_blog_item_total();
		}
	}

	const onChange = (e) => setSearch(e.target.value);

	const onSubmit = (e) => {
		e.preventDefault()
		search_blog(search);
        setSearch('');
        setGoToSearch(!goToSearch);
	}

	useEffect(() => {
		if (blogItems) {
			const blogSlug = slug;

	        const isFavorite = blogItems.find(item => {
	            const normalizedItemBlogId = item.blog.slug;
	            return normalizedItemBlogId === blogSlug;
	        });

			if (isFavorite) {
                setFavoriteBlog(true);
            } else {
                setFavoriteBlog(false);
            }
		}
	}, [blogItems, slug]);

	function getMonthName(monthNumber) {
		const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
		return monthNames[monthNumber];
	}

	function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getUTCDate();
		const month = date.getUTCMonth();
		const year = date.getUTCFullYear().toString().slice(-2);

		const monthName = getMonthName(month);
		return `${day} ${monthName} ${year}`;
	}

	useEffect(() => {
		if (post && post.published) {
			setDatePublishPost(formatDate(post.published))
		}
	}, [post]);

	const [limitedBlogs, setLimitedBlogs] = useState([]);

	useEffect(() => {
		window.scrollTo(0,0)
		get_blog_list();
	}, []);

	useEffect(() => {
        if (blog_list) {
            setLimitedBlogs(blog_list.slice(0, 5));
        }
    }, [blog_list]);

	return (
		<FullWidthLayout>
			<div className='container-blog-title'>
				<h3>Mis blogs</h3>
			</div>
			<div className='wrapper-blogs'>
				<div className='container-blog-detail'>
					<div className='container-header-blog'>
						<h3 className='blog-detail-title'>{post && post.title}</h3>
						<p>{datePublishPost}</p>
					</div>
					<div className='blog-detail-info'>
						<div className='blog-detail-thumbnail'>
							<img src={post && post.thumbnail} />
						</div>
						<p className='post-description'>{post && post.description}</p>
					</div>
					<div className='container-footer-blog'>
						<div>
							<p className='autograph-text'>Brenda Ledesma</p>
						</div>
						<div className='contain-blog-detail-share'>
							<p>Compartir</p>
							<Link to={`mailto:?subject=Check%20this%20out&body=Check%20out%20this%20product:%20${currentUrl}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share' style={{textDecoration: 'none'}} ><FaEnvelope /></Link>
							<Link to={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
							<Link to={`https://www.instagram.com/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
							<Link to={`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share whatsapp' style={{textDecoration: 'none'}}><FaWhatsapp /></Link>
							<Link to={`https://pinterest.com/pin/create/button/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share pinterest' style={{textDecoration: 'none'}}><FaPinterest /></Link>
							<Link to={`https://twitter.com/intent/tweet?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' className='blog-detail-share telegram' style={{textDecoration: 'none'}}><FaTelegramPlane /></Link>
						</div>
					</div>
					<div>
						{favoriteBlog ? (
							<p className='added-blog-favorite'>Favorito <IoMdHeart /></p>
						) : (
							<button className='add-blog-favorite' onClick={addToWishlist}>Añadir a favoritos</button>
							)
						}
					</div>
					{/*<div>Comentarios</div>
					<div>
						<h3>Porque viste este blog:</h3>
						<div></div>
					</div>*/}
				</div>
				<div className='container-right-wrapper'>
					< WrapperBlog
						blog_list={blog_list}
						categories={categories}
						search_blog={search_blog}
						onSubmit={onSubmit}
						onChange={onChange}
						search={search}
					/>
				</div>
			</div>
		</FullWidthLayout>
	)
}

const MapStateToProps = state =>({
	isAuthenticated: state.Auth.isAuthenticated,
	blog_list: state.Blog.blog_list,
	categories: state.Categories.categories,
	post: state.Blog.post,
	blogItems: state.Wishlist.blogItems,
	
})

export default connect(MapStateToProps,{
	get_blog_list,
	get_categories,
	get_blog,
	add_wishlist_blog_item,
	get_wishlist_blog_items,
	get_wishlist_blog_item_total,
})(BlogDetail)