import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PiTimer, PiUsersThree } from "react-icons/pi";
import { IoMdHeart } from "react-icons/io";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaCircle, FaWhatsapp } from 'react-icons/fa';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router'
import '../../styles/recipes.css';
import { FavoriteHeart } from '../../components/aditional/FavoriteHeart';
import { add_wishlist_recipe_item, get_wishlist_recipe_items, get_wishlist_recipe_item_total, remove_wishlist_recipe_item } from '../../redux/actions/wishlist';
import { get_recipe, get_products_used } from '../../redux/actions/recipes';
import { get_recipe_reviews, get_recipe_review, create_recipe_review, update_recipe_review, delete_recipe_review, filter_recipe_reviews } from '../../redux/actions/reviews';


const RecipeDetail = ({
	get_recipe,
	get_products_used,
	add_wishlist_recipe_item,
	get_wishlist_recipe_items,
	get_wishlist_recipe_item_total,
	remove_wishlist_recipe_item,
	get_recipe_reviews,
	get_recipe_review,
	create_recipe_review,
	update_recipe_review,
	delete_recipe_review,
	filter_recipe_reviews,
	isAuthenticated,
	recipe,
	recipeReviews,
	recipeReview,
	recipeItems
}) => {

	const params = useParams();
	const recipeId = params.recipeId;
	const currentUrl = window.location.href;
	const [descriptionVisible, setDescriptionVisible] = useState(true);
	const [detailsVisible, setDetailsVisible] = useState(false);
	const [measuresVisible, setMeasuresVisible] = useState(false);
	const [favoriteRecipe, setFavoriteRecipe] = useState(false);

	useEffect(() => {
		window.scrollTo(0,0)
		get_recipe(recipeId);
		get_wishlist_recipe_items()
		get_wishlist_recipe_item_total()
		get_recipe_reviews(recipeId)
		get_recipe_review(recipeId)
	}, []);

	const addToWishlist = async () => {
		if (!isAuthenticated) {
			return <Navigate to='/login' />
		} else if (
			recipe && 
			recipe !== null &&
			recipe !== undefined
		) {
			await add_wishlist_recipe_item(recipe.id);
			await get_wishlist_recipe_items();
			await get_wishlist_recipe_item_total();
		}
	}

	useEffect(() => {
		if (recipeItems) {
			const normalizedRecipeId = Number(recipeId);

	        const isFavorite = recipeItems.find(item => {
	            const normalizedItemRecipeId = Number(item.recipe.id);
	            return normalizedItemRecipeId === normalizedRecipeId;
	        });

			if (isFavorite) {
                setFavoriteRecipe(true);
            } else {
                setFavoriteRecipe(false);
            }
		}
	}, [recipeItems, recipeId]);

	const removeItem = async () => {
		await remove_wishlist_recipe_item(recipe.id);
	}

	const toggleIngredients = () => {
		setDescriptionVisible(!descriptionVisible);
	};

	const togglePreparation = () => {
		setDetailsVisible(!detailsVisible);
	};


	return (
		<FullWidthLayout>
			<div className='container-detail-recipes'>
				<div className='contain-recipe'>
					<div className='container-image-recipe'>
						<img src={recipe && recipe.photo} />
					</div>
					<div className='container-recipe-info'>
						<div className='container-title-recipe'>
							<h3 className='detail-recipe-title'>{recipe && recipe.name}</h3>
							<div className='container-favorite-recipe'>
								<FavoriteHeart className='favorite-recipe-icon' isItemFavorited={favoriteRecipe} addItem={addToWishlist} deleteItem={removeItem} />
							</div>
						</div>
						<div className='container-duration-dinners'>
							<p className='duration-dinner-description'><PiUsersThree className='icon-duration-dinner' />{recipe && recipe.dinners}</p>
							<p className='duration-dinner-description'><PiTimer className='icon-duration-dinner' />{recipe && recipe.duration} min</p>
						</div>
						<div className='container-detail-description'>
							<div className='toggle-description' onClick={toggleIngredients}><p>Ingredientes</p>{descriptionVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
							{descriptionVisible && 
								<div className='container-ingredients'>
									{recipe && recipe.ingredients.map((ingredient, index) => (
                    					<div className='contain-ingredients' key={index}>
                        					<div className='contain-ingredient'>
                        						<p className='contain-circle-quantity'><FaCircle className='circle-ingredient' />{ingredient.quantity}</p>
                        						<p>{ingredient.name}</p>
                        					</div>
                    					</div>
                					))}
                				</div>
                			}
						</div>
						<div className='container-detail-details'>
							<div className='toggle-description' onClick={togglePreparation}><p>Preparación</p>{detailsVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
							{detailsVisible && 
								<p className='detail-preparation'>{recipe && recipe.preparation}</p>
							}
						</div>
						<div className='container-share-with'>
							<p>Compartir</p>
							<div className='social-share-with'>
								<Link to={`mailto:?subject=Check%20this%20out&body=Check%20out%20this%20product:%20${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaEnvelope className='recipe-icons-share' /></Link>
								<Link to={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaFacebook className='recipe-icons-share' /></Link>
								<Link to={`https://www.instagram.com/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaInstagram className='recipe-icons-share' /></Link>
								<Link to='' target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}}><FaWhatsapp className='recipe-icons-share' /></Link>
								<Link to={`https://pinterest.com/pin/create/button/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaPinterest className='recipe-icons-share' /></Link>
								<Link to={`https://twitter.com/intent/tweet?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaXTwitter className='recipe-icons-share' /></Link>
							</div>
						</div>
					</div>
				</div>
				<div className='container-related-products'>
					<h3></h3>
				</div>
			</div>
		</FullWidthLayout>
	)
}

const MapStateToProps = state =>({
	isAuthenticated: state.Auth.isAuthenticated,
	recipe: state.Recipes.recipe,
	recipeReviews: state.Reviews.recipeReviews,
	recipeReview: state.Reviews.recipeReview,
	recipeItems: state.Wishlist.recipeItems
})

export default connect(MapStateToProps,{
	get_recipe,
	get_products_used,
	add_wishlist_recipe_item,
	get_wishlist_recipe_items,
	get_wishlist_recipe_item_total,
	remove_wishlist_recipe_item,
	get_recipe_reviews,
	get_recipe_review,
	create_recipe_review,
	update_recipe_review,
	delete_recipe_review,
	filter_recipe_reviews
})(RecipeDetail)