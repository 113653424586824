import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { AutoSwiper } from '../../components/aditional/AutoSwiper';
import '../../styles/essen.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


function Promotions(){

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<FullWidthLayout>
			<div className='container-promotions-banner'>
			</div>
			<div className='container-promotions-swiper'>
				<div className='promotions-swiper-text'>
					<h3>Con Julio llegó el frío, ¡Pero también las ofertas!</h3>
					<p>Aprovecha las promociones y beneficios del mes</p>
					<Link to='https://wa.me/+543574405530?text=Hola,%20quiero%20conocer%20las%20promociones%20del%20mes.' className='btn btn-see-recipes' style={{textDecoration: 'none'}}>
						Conócelas
					</Link>
				</div>
				<div className='contain-promotions-swiper'>
					<AutoSwiper />
				</div>
			</div>
			<div className='container-promotions-news'>
				<img src={require(`../../images/financiacion.png`)} />
			</div>
			<div className='container-promotions-promo2'></div>
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({

})

export default connect(MapStateToProps,{

})(Promotions)