import { Link } from 'react-router-dom';
import {
	FaPhoneAlt,
	FaMapMarkerAlt,
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaPinterest,
	FaTwitter,
	FaTelegramPlane,
	FaYoutube,
} from "react-icons/fa";

const Footer = () => {
	return (
		<div className='container-footer'>
			<div className='container-footer-info'>
				<div className='contain-footer-info'>
					<h3 className='contain-logo'>Brenda Ledesma</h3>
					<h4 className='footer-subtitle'>Contacto</h4>
					<p className='footer-contact-item'><FaPhoneAlt className='icon-footer' />+54 9 3574-405530</p>
					<p className='footer-contact-item'><FaEnvelope className='icon-footer' />brendaa3060@gmail.com</p>
				</div>
				<div className='contain-footer-info'>
					<h4 className='footer-subtitle'>Mis Redes</h4>
					<div className ='contain-footer-social'>
						<Link to='https://www.facebook.com/brenda.ledesma.986?locale=es_LA' target='_blank' className='social-footer-links facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
						<Link to='https://www.instagram.com/brenledesmats/' target='_blank' className='social-footer-links instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
						<Link to='https://www.pinterest.es/bledesma0111/' target='_blank' className='social-footer-links pinterest' style={{textDecoration: 'none'}}><FaPinterest /></Link>
						<Link to='/' target='_blank' className='social-footer-links telegram' style={{textDecoration: 'none'}}><FaTelegramPlane /></Link>
						<Link to='/' target='_blank' className='social-footer-links youtube' style={{textDecoration: 'none'}}><FaYoutube /></Link>
					</div>
				</div>
				<div className='contain-footer-info phone-footer-info'>
					<h4 className='footer-subtitle'>Enlaces</h4>
					<div className='footer-main-menu'>
						<Link to='/' className='footer-links' style={{textDecoration: 'none'}}>Inicio</Link>
						<Link to='/contact' className='footer-links' style={{textDecoration: 'none'}}>Contacto</Link>
						<Link to='/about' className='footer-links' style={{textDecoration: 'none'}}>Sobre Mí</Link>
					</div>
				</div>
				<div className='contain-footer-info'>
					<h4 className='footer-subtitle'>Legal</h4>
					<Link to='/cookies-policies' className='footer-links' style={{textDecoration: 'none'}}>Política de Cookies</Link>
					<Link to='/privacy-policies' className='footer-links' style={{textDecoration: 'none'}}>Política de Privacidad</Link>
					<Link to='/purchasing-policies' className='footer-links' style={{textDecoration: 'none'}}>Política de Compras</Link>
				</div>
				<div className='contain-footer-info'>
					<h4 className='footer-subtitle' >Uníte a mi comunidad</h4>
					<Link className='footer-links' style={{textDecoration: 'none'}}>Da click aqui</Link>
					<h4 className='footer-subtitle'>Sumate a mi equipo</h4>
					<Link to='https://wa.me/+543574405530?text=Hola,%20me%20interesa%20unirme%20a%20tu%20equipo%20de%20Essen.' className='footer-links' style={{textDecoration: 'none'}}>¡Sumate ya!</Link>
				</div>
			</div>
			<div className='container-footer-rights'>
				<span className='container-rights'><Link className='link-to-kching' to='https://k-ching.com' target='_blank' style={{textDecoration: 'none'}}>K-Ching </Link> {new Date().getFullYear()} &copy; Todos los Derechos Reservados.</span>
			</div>
		</div>
	);
};

export default Footer;