import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { FaCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const PurchasingPolicies =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<FullWidthLayout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Política de Compras, Envíos y Devoluciones</h2>
					<p>En este blog, nos esforzamos por proporcionar a nuestros clientes una experiencia de compra satisfactoria 
						y transparente. Para garantizar la calidad de nuestro servicio y la satisfacción del cliente, hemos desarrollado la siguiente 
						política de compras, envíos y devoluciones:
					</p>
					<h3>Compras:</h3>
					<p><FaCircle className='icon-policies' />Todos los productos disponibles en nuestro sitio web están 
						sujetos a disponibilidad de Essen.
					</p>
					<p><FaCircle className='icon-policies' />Los precios de los productos no están indicados y son brindados al momento de 
						la solicitarlos por mensaje. Nos reservamos el derecho de modificar los precios en cualquier momento sin previo aviso.
					</p>
					<p><FaCircle className='icon-policies' />Los clientes pueden iniciar sus compras a través de nuestro sitio web, terminando 
						el proceso de pago seguro en la pagina oficial de Essen.
					</p>
					<p><FaCircle className='icon-policies' />Aceptamos una variedad de métodos de pago seguros para la comodidad de nuestros 
						clientes.
					</p>
					<h3>Envíos:</h3>
					<p><FaCircle className='icon-policies' />Procesamos los pedidos dentro de 12 y 14 días hábiles después de recibir el pago.</p>
					<p><FaCircle className='icon-policies' />Ofrecemos envío estándar, con tarifas y tiempos de entrega variables según 
						la ubicación del cliente y el método de envío seleccionado.
					</p>
					<p><FaCircle className='icon-policies' />Los clientes recibirán una confirmación de envío por correo electrónico una vez que 
						su pedido haya sido despachado, junto con un número de seguimiento para rastrear el estado de su envío.
					</p>
					<p><FaCircle className='icon-policies' />Nos esforzamos por garantizar la entrega oportuna de los pedidos, sin embargo, no nos 
						hacemos responsables de retrasos causados por circunstancias fuera de nuestro control, como desastres naturales, problemas 
						de transporte o situaciones de fuerza mayor.
					</p>
					<h3>Devoluciones y Cambios:</h3>
					<p><FaCircle className='icon-policies' />Los cambios y devoluciones estan indicados en la pagina oficial de Essen, nos remitimos
						a dichas politicas al momento de que se presente un cambio o una devolución.
					</p>
					<h3>Excepciones:</h3>
					<p>Algunos productos pueden estar excluidos de nuestra política de devoluciones y cambios por razones de higiene, seguridad o 
						por ser artículos personalizados. Estas exclusiones estarán claramente indicadas en la descripción del producto.
					</p>
					<p>Esta política de compras, envíos y devoluciones es aplicable a todas las transacciones realizadas en este blog. 
						Nos comprometemos a cumplir con los más altos estándares de servicio al cliente y a resolver cualquier problema de manera justa 
						y eficiente. Si tienes alguna pregunta o inquietud, no dudes en ponerte en contacto con nuestro equipo de atención al cliente. 
						¡Gracias por elegirnos como tu destino de compras en línea!
					</p>
				</div>
			</div>
		</FullWidthLayout>
	)
};

export default PurchasingPolicies;