import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';


const initialState = {};

const midddleware = [thunk];

const store = createStore(
	rootReducer,
	initialState,
	// applyMiddleware(...middleware),
	composeWithDevTools(applyMiddleware(...midddleware))
);

export default store;