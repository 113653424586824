import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { ProductCard } from '../../components/essen/ProductCard';
import { useEffect, useState, useRef } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useParams } from 'react-router';
import '../../styles/products.css';
import { get_product_categories } from '../../redux/actions/categories';
import { get_products, get_search_products, get_category_products } from '../../redux/actions/products';
import { Disclosure } from '@headlessui/react';
import { PiMagnifyingGlass } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";


const Products = ({
	get_product_categories,
	get_products,
	get_search_products,
	products,
	search_products,
	product_categories,
	get_category_products,
	category_products,
	count,
	next,
	previous,
}) => {

	const params = useParams();
	const [categoryId, setCategoryId] = useState(0);
    const productsState = useSelector(state => state.Products);
	const [filterByCategory, setFilterByCategory] = useState(false);
	const [searchProduct, setSearchProduct] = useState('');
	const searchFormRef = useRef();
	const [page, setPage] = useState(1);

	useEffect(() => {
		window.scrollTo(0,0)
	    get_product_categories();
	    get_products(page);
		return () => {
            get_search_products('');
        }
	}, []);

	useEffect(() => {
	    window.scrollTo(0, 0);
	    if (filterByCategory && page) {
	        get_category_products(categoryId, page);
	    } else if (page) {
	        get_products(page);
	    }
	}, [page, filterByCategory]);

	useEffect(() => {
		if (parseInt(categoryId) === 0) {
			get_category_products(categoryId, page);
			setFilterByCategory(false);
			get_search_products('');
		}
		else {
			get_category_products(categoryId, page);
			setFilterByCategory(true);			
		}
	}, [categoryId]);

	const onChange = e => {
		setCategoryId(e.target.value)
		setPage(1);
	}

	const onSearchChange = (e) => {
		setSearchProduct(e.target.value);
		get_products(1);
		get_category_products(0);
		setFilterByCategory(false)
	}

	const onSubmitFilter = e => {
		e.preventDefault()
	}

	const onSubmitSearch = e => {
		e.preventDefault()
		get_search_products(searchProduct);
        searchFormRef.current.reset();
        setSearchProduct('');
	}

	const showProducts = () => {
		let display = []
		let items = [];

        if (filterByCategory) {
            items = Array.isArray(category_products) ? category_products : [];
        } else {
            items = Array.isArray(search_products) && search_products.length > 0 ? search_products : products;
        }

		if (items && items.length > 0 && product_categories) {
            items.forEach((product, index) => {
				display.push(
					<div key={index} className="product-card-wrapper">
						<ProductCard product={product} product_categories={product_categories} />
					</div>
				);
			}
		);
	}

	return display;
	};

	const handleNextPage = () => {
        if (productsState.next) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (productsState.previous && page > 1) {
            setPage(page - 1);
        }
    };

	return (
		<FullWidthLayout>
			<div className='container-shop-title'>
				<h3>Productos</h3>
			</div>
			<div className='container-shop'>
				<div className='category-wrapper'>
					<form className='input-search-recipe' onSubmit={e => onSubmitSearch(e)} ref={searchFormRef}>
						<input 
							type='text'
							className=''
							value={searchProduct}
							onChange={onSearchChange}
							required
						/>
						<span>Buscar productos</span>
						<i></i>
						<button className='icon-search-blog' type='submit'><PiMagnifyingGlass /></button>
					</form>
					<form onSubmit={e => onSubmitFilter(e)}>
						<div className='container-filter-products'>
							<div className='input-category-products'>
	                            <input
	                                name='category_id'
	                                onChange={e => onChange(e)}
	                                value={0}
	                                type='radio'
	                                className=''
	                            />
			                    <label className="ml-3 min-w-0 flex-1 text-gray-500">
			                        Mostrar todo
			                    </label>
	                        </div>
							{
			                    product_categories &&
			                    product_categories !== null &&
			                    product_categories !== undefined &&
			                    product_categories.map(category => (
	                                <div key={category.id} className='input-category-products'>
	                                    <input
	                                        name='category_id'
	                                        onChange={e => onChange(e)}
	                                        value={category.id.toString()}
	                                        type='radio'
	                                        className=''
	                                    />
			                            <label className="ml-3 min-w-0 flex-1 text-gray-500">
			                                {category.name}
			                            </label>
	                                </div>
	                            ))
	                    	}
	                    	
	                    </div>
					</form>
				</div>
				<div className='container-product-pagination'>
					<div className='shop-products'>
						{products && showProducts()}
					</div>
					<div className="pagination-controls">
                		<button className='pagination-button' onClick={handlePreviousPage} disabled={!productsState || (productsState.previous === null&& page === 1)}>
                    		<FaAngleLeft className='pagination-button-icon' />Anterior
                		</button>
                		<button className='pagination-button' onClick={handleNextPage} disabled={!productsState || productsState.next === null}>
                    		Siguiente <FaAngleRight className='pagination-button-icon' />
                		</button>
            		</div>
				</div>
			</div>
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({
	products: state.Products.products,
	product_categories: state.Categories.product_categories,
	search_products: state.Products.search_products,
	category_products: state.Products.category_products,
	count: state.Products.count,
	next: state.Products.next,
	previous: state.Products.previous,
})

export default connect(MapStateToProps,{
	get_product_categories,
	get_products,
	get_search_products,
	get_category_products,
})(Products)