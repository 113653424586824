import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { WishlistCard } from '../../components/essen/WishlistCard';
import { connect } from 'react-redux';
import { get_wishlist_blog_items, remove_wishlist_blog_item } from '../../redux/actions/wishlist';
import { useEffect, useState } from 'react';
import { PiCookingPot, PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import '../../styles/navigation.css';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Navigate } from 'react-router';
import { FaCheck } from "react-icons/fa6";
import { get_user_profile, update_user_profile } from '../../redux/actions/profile';


const EditProfile = ({
	update_user_profile,
	get_user_profile,
	profile,
}) => {

	const [favoritesVisible, setFavoritesVisible] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [formData, setFormData] = useState({
        address: '',
        city: '',
        state_province_region: '',
        phone: '',
        country_region: ''
    });

	useEffect(() => {
        window.scrollTo(0, 0);
        get_user_profile()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    };

    useEffect(() => {
        if (profile) {
            setFormData({
                address: profile.address || '',
                city: profile.city || '',
                state_province_region: profile.state_province_region || '',
                phone: profile.phone || '',
                country_region: profile.country_region || ''
                
            });
        }
    }, [profile]);

    const toggleFavorite = () => {
		setFavoritesVisible(!favoritesVisible);
	};

	const onSubmit = async (e) =>{
    	e.preventDefault();
	    await update_user_profile(formData);
	    setRedirect(true);
  	};

  	if (redirect) {
    	return <Navigate to='/dashboard' />;
  	}

	return (
		<FullWidthLayout>
			<div className='container-dashboard'>
				<div className='container-dashboard-items'>
					<div className='container-dashboard-title'>
						<h3>{profile && profile.full_name}</h3>
					</div>
					<Link to='/dashboard' className='dashboard-item item-active' style={{textDecoration: 'none'}}><PiUser className='wish-icon' />Perfil</Link>
					<p className='dashboard-item' onClick={toggleFavorite}><PiHeartLight className='wish-icon' />Favoritos{favoritesVisible ? <FaAngleUp /> : <FaAngleDown />}</p>
					{favoritesVisible ? (
						<div className='container-dashboard-subitem'>
							<Link to='/dashboard/wishlist/blogs' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiChatText className='wish-icon' />Blogs</Link>
							<Link to='/dashboard/wishlist/products' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiCookingPot className='wish-icon' />Productos</Link>
							<Link to='/dashboard/wishlist/recipes' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiArticle className='wish-icon' />Recetas</Link>
						</div>
					) : (
						<></>
					)}
				</div>
				<form className='container-profile' onSubmit={e => onSubmit(e)}>
					<div className='profile-info-edit'>
						<div className='edit-profile-input'>
							<input
					            type='text'
					            name='phone'
					            value={formData.phone}
					            onChange={handleChange}
					            className='input-form-edit'
					        />
							<span>Teléfono</span>
							<i></i>
						</div>
						<div className='edit-profile-input'>
							<input
					            type='text'
					            name='address'
					            value={formData.address}
					            onChange={handleChange}
					            className='input-form-edit'
					        />
					        <span>Dirección</span>
					        <i></i>
					    </div>
						<div className='edit-profile-input'>
							<input
					            type='text'
					            name='city'
					            value={formData.city}
					            onChange={handleChange}
					            required
					            className='input-form-edit'
					        />
							<span>Ciudad</span>
							<i></i>
						</div>
						<div className='edit-profile-input'>
							<input
					            type='text'
					            name='state_province_region'
					            value={formData.state_province_region}
					            onChange={handleChange}
					            required
					            className='input-form-edit'
					        />
							<span>Estado o provincia</span>
							<i></i>
						</div>
						<div className='edit-profile-input'>
							<input
					            type='text'
					            name='country_region'
					            value={formData.country_region}
					            onChange={handleChange}
					            required
					            className='input-form-edit'
					        />
							<span>País</span>
							<i></i>
						</div>
					</div>
					<div className='container-profile-handler'>
						<button className='logout-button' type='submit'><FaCheck />Guardar cambios</button>
					</div>
				</form>
			</div>
		</FullWidthLayout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
	get_user_profile,
	update_user_profile,
}) (EditProfile)