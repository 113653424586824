import {
	GET_RECIPE_SUCCESS,
	GET_RECIPE_FAIL,
	GET_RECIPES_SUCCESS,
	GET_RECIPES_FAIL,
	GET_PRODUCTS_USED_SUCCESS,
	GET_PRODUCTS_USED_FAIL,
	SEARCH_RECIPES_SUCCESS,
	SEARCH_RECIPES_FAIL,
} from './types';
import axios from 'axios';


export const get_recipes = (page = 1, sortBy = 'duration', order = 'desc') => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/get-recipes?page=${page}&sortBy=${sortBy}&order=${order}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_RECIPES_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_RECIPES_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_RECIPES_FAIL
		});
	}
};

export const get_recipe = (recipeId) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/recipe/${recipeId}`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_RECIPE_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_RECIPE_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_RECIPE_FAIL
		});
	}
};

export const get_products_used = (recipe_id) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	};

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/recipes/${recipe_id}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_USED_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_USED_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_USED_FAIL
		});
	}
};

export const get_search_recipes = (search) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({
		search
	});

	console.log(search);

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/recipes/search`, body, config);

		if (res.status === 200) {
			dispatch({
				type: SEARCH_RECIPES_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: SEARCH_RECIPES_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: SEARCH_RECIPES_FAIL
		});
	}
};