import {
	GET_REVIEWS_RECIPE_SUCCESS,
	GET_REVIEWS_RECIPE_FAIL,
	GET_REVIEW_RECIPE_SUCCESS,
	GET_REVIEW_RECIPE_FAIL,
	CREATE_REVIEW_RECIPE_SUCCESS,
	CREATE_REVIEW_RECIPE_FAIL,
	UPDATE_REVIEW_RECIPE_SUCCESS,
	UPDATE_REVIEW_RECIPE_FAIL,
	DELETE_REVIEW_RECIPE_SUCCESS,
	DELETE_REVIEW_RECIPE_FAIL,
	FILTER_REVIEWS_RECIPE_SUCCESS,
	FILTER_REVIEWS_RECIPE_FAIL,
	GET_REVIEWS_BLOG_SUCCESS,
	GET_REVIEWS_BLOG_FAIL,
	GET_REVIEW_BLOG_SUCCESS,
	GET_REVIEW_BLOG_FAIL,
	CREATE_REVIEW_BLOG_SUCCESS,
	CREATE_REVIEW_BLOG_FAIL,
	UPDATE_REVIEW_BLOG_SUCCESS,
	UPDATE_REVIEW_BLOG_FAIL,
	DELETE_REVIEW_BLOG_SUCCESS,
	DELETE_REVIEW_BLOG_FAIL,
	FILTER_REVIEWS_BLOG_SUCCESS,
	FILTER_REVIEWS_BLOG_FAIL,
} from './types';
import axios from 'axios';


export const get_recipe_reviews = recipeId => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/get-recipe-reviews/${recipeId}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_REVIEWS_RECIPE_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_REVIEWS_RECIPE_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_REVIEWS_RECIPE_FAIL
		});
	}
};

export const get_blog_reviews = blogId => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/get-blog-reviews/${blogId}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_REVIEWS_BLOG_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_REVIEWS_BLOG_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_REVIEWS_BLOG_FAIL
		});
	}
};

export const get_recipe_review = recipeId => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json'
			}
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/get-recipe-review/${recipeId}`, config);

			if (res.status === 200) {
				dispatch({
					type: GET_REVIEW_RECIPE_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: GET_REVIEW_RECIPE_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: GET_REVIEW_RECIPE_FAIL
			});
		}
	}
};

export const get_blog_review = blogId => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json'
			}
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/get-blog-review/${blogId}`, config);

			if (res.status === 200) {
				dispatch({
					type: GET_REVIEW_BLOG_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: GET_REVIEW_BLOG_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: GET_REVIEW_BLOG_FAIL
			});
		}
	}
};

export const create_recipe_review = (recipeId, rating, comment) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		};

		const body = JSON.stringify({
			rating,
			comment
		});

		try {
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/reviews/create-recipe-review/${recipeId}`, body, config);

			if (res.status === 201) {
				dispatch({
					type: CREATE_REVIEW_RECIPE_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: CREATE_REVIEW_RECIPE_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: CREATE_REVIEW_RECIPE_FAIL
			});
		}
	}
};

export const create_blog_review = (blogId, rating, comment) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		};

		const body = JSON.stringify({
			rating,
			comment
		});

		try {
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/reviews/create-blog-review/${blogId}`, body, config);

			if (res.status === 201) {
				dispatch({
					type: CREATE_REVIEW_BLOG_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: CREATE_REVIEW_BLOG_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: CREATE_REVIEW_BLOG_FAIL
			});
		}
	}
};

export const update_recipe_review = (recipeId, rating, comment) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		};

		const body = JSON.stringify({
			rating,
			comment
		});

		try {
			const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/reviews/update-recipe-review/${recipeId}`, body, config);

			if (res.status === 200) {
				dispatch({
					type: UPDATE_REVIEW_RECIPE_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: UPDATE_REVIEW_RECIPE_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: UPDATE_REVIEW_RECIPE_FAIL
			});
		}
	}
};

export const update_blog_review = (blogId, rating, comment) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		};

		const body = JSON.stringify({
			rating,
			comment
		});

		try {
			const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/reviews/update-blog-review/${blogId}`, body, config);

			if (res.status === 200) {
				dispatch({
					type: UPDATE_REVIEW_BLOG_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: UPDATE_REVIEW_BLOG_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: UPDATE_REVIEW_BLOG_FAIL
			});
		}
	}
};

export const delete_recipe_review = recipeId => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
			},
			data: {}
		};

		try {
			const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/reviews/delete-recipe-review/${recipeId}`, config);

			if (res.status === 200) {
				dispatch({
					type: DELETE_REVIEW_RECIPE_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: DELETE_REVIEW_RECIPE_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: DELETE_REVIEW_RECIPE_FAIL
			});
		}
	}
};

export const delete_blog_review = blogId => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json',
			},
			data: {}
		};

		try {
			const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/reviews/delete-blog-review/${blogId}`, config);

			if (res.status === 200) {
				dispatch({
					type: DELETE_REVIEW_BLOG_SUCCESS,
					payload: res.data
				});
			} else {
				dispatch({
					type: DELETE_REVIEW_BLOG_FAIL
				});
			}
		} catch(err) {
			dispatch({
				type: DELETE_REVIEW_BLOG_FAIL
			});
		}
	}
};

export const filter_recipe_reviews = (recipeId, rating) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	let myRating;

	if(rating === 0.5)
		myRating = '0.5';
	else if(rating === 1 || rating === 1.0)
		myRating = '1.0';
	else if(rating === 1.5)
		myRating = '1.5';
	else if(rating === 2 || rating === 2.0)
		myRating = '2.0';
	else if(rating === 2.5)
		myRating = '2.5';
	else if(rating === 3 || rating === 3.0)
		myRating = '3.0';
	else if(rating === 3.5)
		myRating = '3.5';
	else if(rating === 4 || rating === 4.0)
		myRating = '4.0';
	else if(rating === 4.5)
		myRating = '4.5';
	else
		myRating = '5.0';

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/filter-recipe-reviews/${recipeId}?rating=${myRating}`, config);

		if (res.status === 200) {
			dispatch({
				type: FILTER_REVIEWS_RECIPE_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: FILTER_REVIEWS_RECIPE_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: FILTER_REVIEWS_RECIPE_FAIL
		});
	}
};

export const filter_blog_reviews = (blogId, rating) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	let myRating;

	if(rating === 0.5)
		myRating = '0.5';
	else if(rating === 1 || rating === 1.0)
		myRating = '1.0';
	else if(rating === 1.5)
		myRating = '1.5';
	else if(rating === 2 || rating === 2.0)
		myRating = '2.0';
	else if(rating === 2.5)
		myRating = '2.5';
	else if(rating === 3 || rating === 3.0)
		myRating = '3.0';
	else if(rating === 3.5)
		myRating = '3.5';
	else if(rating === 4 || rating === 4.0)
		myRating = '4.0';
	else if(rating === 4.5)
		myRating = '4.5';
	else
		myRating = '5.0';

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/filter-blog-reviews/${blogId}?rating=${myRating}`, config);

		if (res.status === 200) {
			dispatch({
				type: FILTER_REVIEWS_BLOG_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: FILTER_REVIEWS_BLOG_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: FILTER_REVIEWS_BLOG_FAIL
		});
	}
};