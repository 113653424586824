import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/static-pages.css';


function About(){

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<FullWidthLayout>
			<div className='container-shop-title'>
				<h3>Sobre Mí</h3>
			</div>
			<div className='container-about-cook'>
				<div className='about-cook-img'>
					<img src={require(`../../images/fondo-cocina.png`)} />
				</div>
				<div className='contain-about-cook'>
					<h3>Amor por la Cocina</h3>
					<p>Cada receta que preparo se convierte en un acto de amor y creatividad que disfruto compartir con quienes me rodean, disfruto experimentando con nuevos sabores en la cocina, convirtiendo cada comida en una experiencia deliciosa y creativa.</p>
					<Link to='/recipes' className='btn btn-see-know' style={{textDecoration: 'none'}}>Conoce mis recetas</Link>
				</div>
			</div>
			<div className='container-about-cats'>
				<div className='contain-about-cats'>
					<h3>Amor por los Gatos</h3>
					<p>Apasionada por estos adorables compañeros peludos que llenan mi hogar de alegría. Desde un Instagram especial para ellos, me dedico a 
					compartir fotos de gatos en adopción en nuestro pueblo, ayudando a encontrarles un hogar amoroso. Mi amor por los gatos va más allá de 
					la admiración; me impulsa a involucrarme activamente en su bienestar y cuidado. Únete a mí en esta misión de amor y adopción.</p>
					<Link to='https://www.instagram.com/adopcionfelina_rio1/' target='_blank' className='btn btn-see-more' style={{textDecoration: 'none'}}>Siguenos en Instagram</Link>
				</div>
				<div className='about-cats-img'>
					<img src={require(`../../images/fondo-gatos.png`)} />
				</div>
			</div>
			<div className='container-about-sales'>
				<div className='about-sales-img'>
					<img src={require(`../../images/fondo-ventas.png`)} />
				</div>
				<div className='contain-about-sales'>
					<h3>Amor por las Ventas</h3>
					<p>He aplicado el arte de vender en una amplia variedad de productos, desde blanquería y ropa, cosméticos, hasta artículos personalizados como 
					sublimación y ploteo, joyería y bienes inmuebles. Cada venta es una oportunidad para conectar con personas, entender sus necesidades 
					y ofrecerles soluciones que superen sus expectativas. Mi enfoque siempre ha sido la mejora continua, buscando nuevas estrategias y 
					métodos para ofrecer el mejor servicio posible.</p>
					<Link to='/products' className='btn btn-see-recipes' style={{textDecoration: 'none'}}>Mira mis productos</Link>
				</div>
			</div>
			<div className='container-about-god'>
				<h3>Amor por Dios</h3>
				<div className='contain-about-god'>
					<p>¿Con qué limpiará el joven su camino?</p>
					<p>Con guardar tu palabra.</p>
					<p className='bible-cite'>Salmos 119:9 - RVR1960</p>
				</div>
			</div>
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({

})

export default connect(MapStateToProps,{

})(About)