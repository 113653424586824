import {
	GET_REVIEWS_RECIPE_SUCCESS,
	GET_REVIEWS_RECIPE_FAIL,
	GET_REVIEW_RECIPE_SUCCESS,
	GET_REVIEW_RECIPE_FAIL,
	CREATE_REVIEW_RECIPE_SUCCESS,
	CREATE_REVIEW_RECIPE_FAIL,
	UPDATE_REVIEW_RECIPE_SUCCESS,
	UPDATE_REVIEW_RECIPE_FAIL,
	DELETE_REVIEW_RECIPE_SUCCESS,
	DELETE_REVIEW_RECIPE_FAIL,
	FILTER_REVIEWS_RECIPE_SUCCESS,
	FILTER_REVIEWS_RECIPE_FAIL,
	GET_REVIEWS_BLOG_SUCCESS,
	GET_REVIEWS_BLOG_FAIL,
	GET_REVIEW_BLOG_SUCCESS,
	GET_REVIEW_BLOG_FAIL,
	CREATE_REVIEW_BLOG_SUCCESS,
	CREATE_REVIEW_BLOG_FAIL,
	UPDATE_REVIEW_BLOG_SUCCESS,
	UPDATE_REVIEW_BLOG_FAIL,
	DELETE_REVIEW_BLOG_SUCCESS,
	DELETE_REVIEW_BLOG_FAIL,
	FILTER_REVIEWS_BLOG_SUCCESS,
	FILTER_REVIEWS_BLOG_FAIL,
} from '../actions/types';


const initialState = {
	recipeReview: null,
	recipeReviews: null,
	blogReview: null,
	blogReviews: null,
}

export default function Reviews(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case GET_REVIEWS_RECIPE_SUCCESS:
			return {
				...state,
				recipeReviews: payload.reviews
			}
		case GET_REVIEWS_RECIPE_FAIL:
			return {
				...state,
				recipeReviews: []
			}
		case GET_REVIEWS_BLOG_SUCCESS:
			return {
				...state,
				blogReviews: payload.reviews
			}
		case GET_REVIEWS_BLOG_FAIL:
			return {
				...state,
				blogReviews: []
			}
		case GET_REVIEW_RECIPE_SUCCESS:
			return {
				...state,
				recipeReview: payload.review
			}
		case GET_REVIEW_RECIPE_FAIL:
			return {
				...state,
				recipeReview: {}
			}
		case GET_REVIEW_BLOG_SUCCESS:
			return {
				...state,
				blogReview: payload.review
			}
		case GET_REVIEW_BLOG_FAIL:
			return {
				...state,
				blogReview: {}
			}
		case UPDATE_REVIEW_RECIPE_SUCCESS:
			return {
				...state,
				recipeReview: payload.review,
				recipeReviews: payload.reviews
			}
		case UPDATE_REVIEW_RECIPE_FAIL:
			return {
				...state
			}
		case UPDATE_REVIEW_BLOG_SUCCESS:
			return {
				...state,
				blogReview: payload.review,
				blogReviews: payload.reviews
			}
		case UPDATE_REVIEW_BLOG_FAIL:
			return {
				...state
			}
		case DELETE_REVIEW_RECIPE_SUCCESS:
			return {
				...state,
				recipeReview: {},
				recipeReviews: payload.reviews
			}
		case DELETE_REVIEW_RECIPE_FAIL:
			return {
				...state
			}
		case DELETE_REVIEW_BLOG_SUCCESS:
			return {
				...state,
				blogReview: {},
				blogReviews: payload.reviews
			}
		case DELETE_REVIEW_BLOG_FAIL:
			return {
				...state
			}
		case FILTER_REVIEWS_RECIPE_SUCCESS:
			return {
				...state,
				recipeReviews: payload.reviews
			}
		case FILTER_REVIEWS_RECIPE_FAIL:
			return {
				...state
			}
		case FILTER_REVIEWS_BLOG_SUCCESS:
			return {
				...state,
				blogReviews: payload.reviews
			}
		case FILTER_REVIEWS_BLOG_FAIL:
			return {
				...state
			}
		default:
			return state
	}
}