import React from 'react';
import '../../styles/components.css';
import { Link } from 'react-router-dom';

export const WhatsAppButton = () => {
	return (
		<Link to='https://wa.me/+543574405530?text=Hola,%20me%20interesa%20comprar%20una%20Essen.' className='whatsapp-button' style={{textDecoration: 'none'}}>
			<div className='whatsapp-image'><img src={require(`../../images/whatsapp-logo.png`)} alt="WhatsApp Icon" /></div>
			<p>Estoy en línea ¡Escribime!</p>
		</Link>
	);
};