import { combineReducers } from 'redux';
import Alert from './alert';
import Auth from './auth';
import Blog from './blog';
import Categories from './categories';
import Products from './products';
import Recipes from './recipes';
import Wishlist from './wishlist';
import Reviews from './reviews';
import Profile from './profile';


export default combineReducers({
	Alert,
	Auth,
	Blog,
	Categories,
	Products,
	Recipes,
	Wishlist,
	Reviews,
	Profile,
})