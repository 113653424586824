import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { get_product, get_promo } from '../../redux/actions/products';
import '../../styles/products.css';
import { IoCartOutline } from "react-icons/io5";
import { IoMdHeart, IoMdClose } from "react-icons/io";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { Oval } from 'react-loader-spinner';
import { add_wishlist_item, get_wishlist_items, get_wishlist_item_total, remove_wishlist_item } from '../../redux/actions/wishlist';
import { Navigate } from 'react-router'
import { FavoriteHeart } from '../../components/aditional/FavoriteHeart';


const ProductDetail = ({
	get_product,
	get_promo,
	promo,
	product,
	images,
	add_wishlist_item,
	get_wishlist_items,
	get_wishlist_item_total,
	remove_wishlist_item,
	isAuthenticated,
	items,
}) => {

	const params = useParams();
	const productId = params.productId;
	const currentUrl = window.location.href;
	const [descriptionVisible, setDescriptionVisible] = useState(true);
	const [detailsVisible, setDetailsVisible] = useState(false);
	const [measuresVisible, setMeasuresVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [redirectToCart, setRedirectToCart] = useState(false);
	const [favoriteProduct, setFavoriteProduct] = useState(false);
	const [seePromos, setSeePromos] = useState(false);

	useEffect(() => {
		window.scrollTo(0,0)
		get_product(productId);
		get_wishlist_items()
		get_wishlist_item_total()
		get_promo()
	}, []);

	const toggleDescription = () => {
		setDescriptionVisible(!descriptionVisible);
	};

	const toggleDetails = () => {
		setDetailsVisible(!detailsVisible);
	};

	const toggleMeasures = () => {
		setMeasuresVisible(!measuresVisible);
	};

	const addToWishlist = async () => {
		if (!isAuthenticated) {
			return <Navigate to='/login' />
		} else if (
			product && 
			product !== null &&
			product !== undefined
		) {
			await add_wishlist_item(product.id);
			await get_wishlist_items();
			await get_wishlist_item_total();
		}
	}

	const removeItem = async () => {
		await remove_wishlist_item(product.id);
	}

	const handlePromo = () => {
		setSeePromos(!seePromos);
	}

	useEffect(() => {
		if (items) {
			const normalizedProductId = Number(productId);

	        const isFavorite = items.find(item => {
	            const normalizedItemProductId = Number(item.product.id);
	            return normalizedItemProductId === normalizedProductId;
	        });

			if (isFavorite) {
                setFavoriteProduct(true);
            } else {
                setFavoriteProduct(false);
            }
		}
	}, [items, productId]);

	return (
		<FullWidthLayout>
			<div className='container-detail-products'>
				<div className='contain-product'>
					<div className='container-image-product'>
						<img src={product && product.main_photo} />
					</div>
					<div className='container-product-info'>
						<div className='container-title-fav'>
							<h3 className='detail-title'>{product && product.name}</h3>
							<div className={`container-favorite-product ${product && product.product_line}-background`}>
								<FavoriteHeart className='favorite-icon' isItemFavorited={favoriteProduct} addItem={addToWishlist} deleteItem={removeItem} />
							</div>
						</div>
						<div className='container-detail-description'>
							<div className='toggle-description' onClick={toggleDescription}><p>Descripción</p>{descriptionVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
							{descriptionVisible && <p className='detail-description'>{product && product.description}</p>}
						</div>
						<div className='container-detail-details'>
							<div className='toggle-description' onClick={toggleDetails}><p>Detalles del producto</p>{detailsVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
							{detailsVisible && 
								<>
									{product.product_type && <p className='detail-details'>Tipo: <span>{product && product.product_type}</span></p>}
									{product.diameter &&<p className='detail-details'>Diametro: <span>{product && product.diameter}</span></p>}
									{product.capacitance &&<p className='detail-details'>Capacidad: <span>{product && product.capacitance}</span></p>}
									{product.diners &&<p className='detail-details'>Comensales: <span>{product && product.diners}</span></p>}
									{product.inner_coating &&<p className='detail-details'>Recubrimiento interior: <span>{product && product.inner_coating}</span></p>}
									{product.outer_coating &&<p className='detail-details'>Recubrimiento exterior: <span>{product && product.outer_coating}</span></p>}
								</>
							}
						</div>
						<div className='container-detail-measures'>
							<div className='toggle-description' onClick={toggleMeasures}><p>Medidas del producto</p>{measuresVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
							{measuresVisible && <div className='toggle-measures-image'><img src={product && product.measures_photo} /></div>}
						</div>
						<div className='container-share-with'>
							<p>Compartir</p>
							<div className='social-share-with'>
								<Link to={`mailto:?subject=Check%20this%20out&body=Check%20out%20this%20product:%20${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaEnvelope className={`icons-share ${product && product.product_line}`} /></Link>
								<Link to={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaFacebook className={`icons-share ${product && product.product_line}`} /></Link>
								<Link to={`https://www.instagram.com/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaInstagram className={`icons-share ${product && product.product_line}`} /></Link>
								<Link to={`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}}><FaWhatsapp className={`icons-share ${product && product.product_line}`} /></Link>
								<Link to={`https://pinterest.com/pin/create/button/?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaPinterest className={`icons-share ${product && product.product_line}`} /></Link>
								<Link to={`https://twitter.com/intent/tweet?url=${currentUrl}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}} ><FaXTwitter className={`icons-share ${product && product.product_line}`} /></Link>
							</div>
						</div>
						<Link to='https://wa.me/+543574405530?text=Hola,%20me%20interesa%20comprar%20una%20Essen.' className={`whatsapp-button-link ${product && product.product_line}-background`} style={{textDecoration: 'none'}}>
							<div className='container-whatsapp-link'>
								<img src={require(`../../images/whatsapp-logo.png`)} />
							</div>
							<div>
								<p>¡Hola, quiero mi Essen!</p>
							</div>
						</Link>
						<div className='pay-methods'>
							<div className='contain-pay-methods'>
								<p>Medios de pago disponibles</p>
								<img src={require(`../../images/visa.png`)} />
								<img src={require(`../../images/mastercard.jpg`)} />
								<img src={require(`../../images/american.png`)} />
								<img src={require(`../../images/dinero.png`)} />
							</div>
							<p className='promos-open' onClick={handlePromo}>Ver promociones disponibles</p>
						</div>
					</div>
				</div>
				{/*<div className='container-related-products'>
					<h3>Recetas con este producto</h3>
				</div>*/}
			</div>
			<div className={`promo-active ${seePromos ? 'active' : ''}`}>
				<img src={promo && promo.photo} />
				<IoMdClose className='close-promos' onClick={handlePromo}/>
			</div>
		</FullWidthLayout>
	)
};

const mapStateToProps = state => ({
	product: state.Products.product,
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Wishlist.items,
	promo: state.Products.promo,
})

export default connect(mapStateToProps, {
	get_product,
	add_wishlist_item,
	get_wishlist_items,
	get_wishlist_item_total,
	remove_wishlist_item,
	get_promo,
}) (ProductDetail)