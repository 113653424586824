import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { RecipeWishCard } from '../../components/wishlist/RecipeWishCard';
import { connect } from 'react-redux';
import { get_wishlist_recipe_items, remove_wishlist_recipe_item } from '../../redux/actions/wishlist';
import { useEffect, useState } from 'react';
import { PiCookingPot, PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import '../../styles/navigation.css';
import { Link } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';


const WishProducts = ({
	get_wishlist_recipe_items,
	remove_wishlist_recipe_item,
	recipeItems,
}) => {

	const [render, setRender] = useState(false);
	const [favoritesVisible, setFavoritesVisible] = useState(true);

	useEffect(() => {
        window.scrollTo(0, 0);
        get_wishlist_recipe_items()
    }, [render])

    const removeItem = async (item) => {
		await remove_wishlist_recipe_item(item.recipe.id);
	}

	const showRecipes = () => {
    	return (
    		<div className='cards-contain-wish'>
				{
					recipeItems &&
					recipeItems !== null &&
					recipeItems !== undefined &&
					recipeItems.length !== 0 &&
					recipeItems.map((item, index) => {

						return (
							<div key={index}>
								<RecipeWishCard
									item={item}
									removeItem={removeItem}
									render={render}
									setRender={setRender}
								/>
							</div>
						)
					})
				}
			</div>
    	)
    };

    const toggleFavorite = () => {
		setFavoritesVisible(!favoritesVisible);
	};

	return (
		<FullWidthLayout>
			
			<div className='container-dashboard'>
				<div className='container-dashboard-items'>
					<div className='container-dashboard-title'>
						<h3>Favoritos</h3>
					</div>
					<Link to='/dashboard' className='dashboard-item' style={{textDecoration: 'none'}}><PiUser className='wish-icon' />Perfil</Link>
					<p className='dashboard-item item-active' onClick={toggleFavorite}><PiHeartLight className='wish-icon' />Favoritos{favoritesVisible ? <FaAngleUp /> : <FaAngleDown />}</p>
					{favoritesVisible ? (
						<div className='container-dashboard-subitem'>
							<Link to='/dashboard/wishlist/blogs' className='dashboard-sub-item sub-item-noactive' style={{textDecoration: 'none'}}><PiChatText className='wish-icon' />Blogs</Link>
							<Link to='/dashboard/wishlist/products' className='dashboard-sub-item sub-item-noactive' style={{textDecoration: 'none'}}><PiCookingPot className='wish-icon' />Productos</Link>
							<Link to='/dashboard/wishlist/recipes' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiArticle className='wish-icon' />Recetas</Link>
						</div>
					) : (
						<></>
					)}
				</div>
				<div className='contain-favorites-cards'>
					{showRecipes()}
				</div>
			</div>
		</FullWidthLayout>
	)
}

const mapStateToProps = state => ({
	total_items: state.Wishlist.total_items,
	isAuthenticated: state.Auth.isAuthenticated,
	recipeItems: state.Wishlist.recipeItems,
})

export default connect(mapStateToProps, {
	get_wishlist_recipe_items,
	remove_wishlist_recipe_item,
}) (WishProducts)