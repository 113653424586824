import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { login } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import '../../styles/auth.css';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const Login = ({
 	login, 
 	loading,
 	isAuthenticated
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});


	const {
		email,
		password
	} = formData;

	const [activated, setActivated] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	login(email, password);
    	setActivated(true);
    	window.scrollTo(0,0);
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

  	if (activated && isAuthenticated && !loading)
		return <Navigate to='/' />;

	return (
		<FullWidthLayout>
			<div className='container-login'>
				<div className='container-form'>
					<span className='border-line'></span>
					<form className='form-content' onSubmit={e => onSubmit(e)}>
						<div className='contain-form'>
							<h3 className='form-title'>Inicio de Sesión</h3>
							<div className='input-contain'>
								<input
									name='email'
									value={email}
									onChange={e => onChange(e)}
									type='email'
									required
									className='input-form' 
								/>
								<span>Correo Electrónico</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='password'
									value={password}
									onChange={e => onChange(e)}
									type={passwordType}
									required
									className='input-form'
								/>
								{iconShow ? (
									<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
								) : (
									<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
								)}
								<span>Contraseña</span>
								<i></i>
							</div>
							{loading ? 
								<button type='button' className='button-form'><Oval
				  					visible={true}
									height="25"
									width="25"
									color="#ddd"
									ariaLabel="oval-loading"
									wrapperStyle={{}}
									wrapperClass=""
									secondaryColor="#ddd"
									/>
								</button> : 
								<button type='submit' className='button-form'>
									Iniciar Sesión	
								</button>
							}
							<div className="text-sm">
				                <Link to="/reset_password" className='link-change' style={{textDecoration: 'none'}}>
				                    ¿Olvidaste tu contraseña?
				              	</Link>
				            </div>
				            <div>
								<p className='change-login-signup'>¿Aun no tienes una cuenta?<Link to='/register' className='link-change' style={{textDecoration: 'none'}}>Registrate</Link></p>
							</div>
				        </div>
					</form>
				</div>
			</div>
		</FullWidthLayout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading,
	isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
	login 
}) (Login)