import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeart } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { BiSolidStar } from "react-icons/bi";


export const RecipeWishCard = ({ 
	item,
	removeItem,
	render,
	setRender,
}) => {

	return (
		<div className='container-wish-recipe-card'>
			<div className='container-wish-product'>
				<div className='wish-recipe-image'>
					<img src={item.recipe.photo} />
				</div>
				<div className='button-remove-contain'>
					<button onClick={() => removeItem(item)}>
						<IoMdClose />
					</button>
				</div>
				<div className='wish-item-recipe-info'>
					<div className='wish-product-info'>
						<Link to={`/recipes/${item.recipe.id}`} className='wish-card-link' style={{textDecoration: 'none'}}>{item.recipe.name}</Link>
					</div>
				</div>
			</div>
		</div>
	)
};