import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { WishlistCard } from '../../components/essen/WishlistCard';
import { connect } from 'react-redux';
import { get_wishlist_blog_items, remove_wishlist_blog_item } from '../../redux/actions/wishlist';
import { useEffect, useState } from 'react';
import { PiCookingPot, PiArticle, PiChatText, PiUser, PiHeartLight } from "react-icons/pi";
import '../../styles/navigation.css';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Navigate } from 'react-router';
import { FaArrowRightArrowLeft, FaArrowRightFromBracket, FaPenToSquare } from "react-icons/fa6";
import { get_user_profile } from '../../redux/actions/profile';


const Dashboard = ({
	logout,
	get_user_profile,
	profile,
}) => {

	const [render, setRender] = useState(false);
	const [favoritesVisible, setFavoritesVisible] = useState(false);
	const [redirect, setRedirect] = useState(false);

	

	useEffect(() => {
        window.scrollTo(0, 0);
        get_user_profile()
    }, [render])

    const toggleFavorite = () => {
		setFavoritesVisible(!favoritesVisible);
	};

	const logoutHandler = () => {
		logout();
		setRedirect(true);
	}
	if (redirect) {
		window.location.reload(false);
		return <Navigate to='/' />
	}

	return (
		<FullWidthLayout>
			<div className='container-dashboard'>
				<div className='container-dashboard-items'>
					<div className='container-dashboard-title'>
						<h3>{profile && profile.full_name}</h3>
					</div>
					<Link to='/dashboard' className='dashboard-item item-active' style={{textDecoration: 'none'}}><PiUser className='wish-icon' />Perfil</Link>
					<p className='dashboard-item' onClick={toggleFavorite}><PiHeartLight className='wish-icon' />Favoritos{favoritesVisible ? <FaAngleUp /> : <FaAngleDown />}</p>
					{favoritesVisible ? (
						<div className='container-dashboard-subitem'>
							<Link to='/dashboard/wishlist/blogs' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiChatText className='wish-icon' />Blogs</Link>
							<Link to='/dashboard/wishlist/products' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiCookingPot className='wish-icon' />Productos</Link>
							<Link to='/dashboard/wishlist/recipes' className='dashboard-sub-item' style={{textDecoration: 'none'}}><PiArticle className='wish-icon' />Recetas</Link>
						</div>
					) : (
						<></>
					)}
				</div>
				<div className='container-profile'>
					<div className='profile-info'>
						<p className='profile-info-text'>Nombre y Apellido:<span>{profile && profile.full_name}</span></p>
						<p className='profile-info-text'>Email:<span>{profile && profile.email}</span></p>
						<p className='profile-info-text'>Teléfono:<span>{profile && profile.phone}</span></p>
						<p className='profile-info-text'>Dirección:<span>{profile && profile.address}</span></p>
						<p className='profile-info-text'>Ciudad:<span>{profile && profile.city}</span></p>
						<p className='profile-info-text'>Estado o provincia:<span>{profile && profile.state_province_region}</span></p>
						<p className='profile-info-text'>País:<span>{profile && profile.country_region}</span></p>
					</div>
					<div className='container-profile-handler'>
						<Link to='/edit_profile' className='profile-handler-link' style={{textDecoration: 'none'}}><FaPenToSquare />Editar perfil</Link>
						<Link to='/reset_password' className='profile-handler-link' style={{textDecoration: 'none'}}><FaArrowRightArrowLeft />Cambiar contraseña</Link>
						<button className='logout-button' onClick={logoutHandler}><FaArrowRightFromBracket />Cerrar sesión</button>
					</div>
				</div>
			</div>
		</FullWidthLayout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
	logout,
	get_user_profile,
}) (Dashboard)