import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { PiTimer, PiUsersThree } from "react-icons/pi";


export const RecipeCard = ({ 
	recipe,
}) => {

	return (
		<div className='container-recipe-card'>
			<div className='recipe-card-image'>
				<Link to={`/recipes/${recipe.id}`}><img src={recipe && recipe.photo} /></Link>
				<div className='stocked-recipe'>
				</div>
				<div className='recipe-image-icon'>
					<IoMdHeartEmpty />
				</div>
			</div>
			<div className='recipe-card-details'>
				<p className='card-details-text'><PiUsersThree className='card-details-icons' />{recipe && recipe.dinners}</p>
				<p className='card-details-text'><PiTimer className='card-details-icons' />{recipe && recipe.duration} min</p>
			</div>
			<div className='recipe-card-info'>
				<Link to={`/recipes/${recipe.id}`} style={{textDecoration: 'none'}}><h4 className='recipe-card-title'>{recipe && recipe.name}</h4></Link>
			</div>
		</div>
	)
};