import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeart } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { IoMdClose } from "react-icons/io";


export const BlogWishCard = ({ 
	item,
	remove_wishlist_item,
	render,
	setRender,
	removeItem,
}) => {


	return (
		<div className='container-wish-product-card'>
			<div className='container-wish-product'>
				<div className='wish-product-image'>
					<img src={item.blog.thumbnail} />	
				</div>
				<div className='button-remove-contain'>
					<button onClick={() => removeItem(item)}>
						<IoMdClose />
					</button>
				</div>
				<div className='wish-item-info'>
					<div className='wish-product-info'>
						<Link to={`/blogs/${item.blog.slug}`} className='wish-card-link' style={{textDecoration: 'none'}}>{item.blog.title}</Link>
					</div>
				</div>
			</div>
		</div>
	)
};