import React from 'react';

const ParallaxSection = ({ children }) => {
    return (
        <div className="parallax-section">
            <div className="parallax-background"></div>
            <div className="parallax-content">
                {children}
            </div>
        </div>
    );
};

export default ParallaxSection