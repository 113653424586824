import {
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_BY_ARRIVAL_SUCCESS,
	GET_PRODUCTS_BY_ARRIVAL_FAIL,
	GET_PRODUCTS_BY_TYPE_SUCCESS,
	GET_PRODUCTS_BY_TYPE_FAIL,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	SEARCH_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS_FAIL,
	CATEGORY_PRODUCTS_SUCCESS,
	CATEGORY_PRODUCTS_FAIL,
	GET_PROMOS_SUCCESS,
	GET_PROMOS_FAIL,
} from '../actions/types'


const initialState = {
	products: [],
	products_arrival: null,
	products_type: null,
	product: null,
	search_products: null,
	category_products: null,
	promo: null,
	count: 0,
    next: null,
    previous: null,
}

export default function Products(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case GET_PRODUCTS_SUCCESS:
			return {
				...state,
				products: action.payload.results,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case GET_PRODUCTS_FAIL:
			return {
				...state,
				products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case GET_PRODUCTS_BY_ARRIVAL_SUCCESS:
			return {
				...state,
				products_arrival: payload.products
			}
		case GET_PRODUCTS_BY_ARRIVAL_FAIL:
			return {
				...state,
				products_arrival: null
			}
		case GET_PRODUCTS_BY_TYPE_SUCCESS:
			return {
				...state,
				products_type: payload.products
			}
		case GET_PRODUCTS_BY_TYPE_FAIL:
			return {
				...state,
				products_type: null
			}
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				product: payload.product
			}
		case GET_PRODUCT_FAIL:
			return {
				...state,
				product: null
			}
		case SEARCH_PRODUCTS_SUCCESS:
			return {
				...state,
				search_products: payload.search_products,
			}
		case SEARCH_PRODUCTS_FAIL:
			return {
				...state,
				search_products: null
			}
		case CATEGORY_PRODUCTS_SUCCESS:
			return {
				...state,
				category_products: action.payload.results.category_products,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case CATEGORY_PRODUCTS_FAIL:
			return {
				...state,
				category_products: [],
				count: 0,
				next: null,
				previous: null,
			}
		case GET_PROMOS_SUCCESS:
			return {
				...state,
				promo: payload.promo
			}
		case GET_PROMOS_FAIL:
			return {
				...state,
				promo: null
			}
		default:
			return state
	}
}