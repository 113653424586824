import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { get_blog_list, get_blog_list_category, get_blog_list_page, search_blog } from '../../redux/actions/blog';
import { get_categories } from '../../redux/actions/categories';
import { BlogCard } from '../../components/blog/BlogCard';
import '../../styles/blogs.css';
import { PiMagnifyingGlass } from "react-icons/pi";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import WrapperBlog from '../../components/blog/WrapperBlog';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";


const BlogCategory = ({
	get_blog_list,
	get_blog_list_category,
	get_blog_list_page,
	search_blog,
	blog_list,
	blog_list_category,
	get_categories,
	categories,
	count,
	next,
	previous,
}) => {

	const params = useParams();
	const categoryId = params.categoryId
	const [categoryName, setCategoryName] = useState(null);
	const [search, setSearch] = useState('');
	const [goToSearch, setGoToSearch] = useState(false);
	const blogsState = useSelector(state => state.Blogs);
	const [page, setPage] = useState(1);

	useEffect(() => {
		window.scrollTo(0,0);
		get_blog_list();
		get_categories();
		get_blog_list_category(categoryId);
	}, [categoryId]);

	useEffect(() => {
		if (categories) {
			const category = categories.find(category => (Number(category.id)) === (Number(categoryId)));

			if (category) {
				setCategoryName(category.name);
			} else {
				setCategoryName(null);
			}
		}
	}, [categories, categoryId]);

	const onChange = (e) => setSearch(e.target.value);

	const onSubmit = (e) => {
		e.preventDefault()
		search_blog(search);
        setSearch('');
        setGoToSearch(!goToSearch);
	}

	if(goToSearch){
    	return <Navigate to='/blogs/search' />;
  	}

	const showBlogs = () => {
		let results = []
		let display = []

		if (
		    blog_list_category &&
		    blog_list_category !== null && 
		    blog_list_category !== undefined
		) {
		    blog_list_category.map((blog, index) => {
		        return display.push(
			        <div key={index}>
			            <BlogCard 
			                blog={blog}
			            />
			        </div>
		        );
		    });
		}

		for (let i = 0; i < display.length; i += 3) {
			results.push(
				<div key={i} className='blogs-listed'>
					{display[i] ? display[i] : <div></div>}
					{display[i+1] ? display[i+1] : <div></div>}
					{display[i+2] ? display[i+2] : <div></div>}
				</div>
			)
		}
		return results;
	}

	const handleNextPage = () => {
        if (blogsState.next) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (blogsState.previous && page > 1) {
            setPage(page - 1);
        }
    };

	return (
		<FullWidthLayout>
			<div className='container-blog-title'>
				<h3>Mis blogs</h3>
			</div>
			<div className='wrapper-blogs'>
				<div className='container-blogs'>
					<h3>{categoryName}</h3>
					<div className='blog-listed'>
						{showBlogs()}
					</div>
					<div className="pagination-controls">
                		<button className='pagination-button' onClick={handlePreviousPage} disabled={!blogsState || (blogsState.previous === null && page === 1)}>
                    		<FaAngleLeft className='pagination-button-icon' />Anterior
                		</button>
                		<button className='pagination-button' onClick={handleNextPage} disabled={!blogsState || blogsState.next === null}>
                    		Siguiente <FaAngleRight className='pagination-button-icon' />
                		</button>
            		</div>
				</div>
				<div className='container-right-wrapper'>
					< WrapperBlog 
						blog_list={blog_list}
						categories={categories}
						search_blog={search_blog}
						onSubmit={onSubmit}
						onChange={onChange}
						search={search}
					/>
				</div>
			</div>
		</FullWidthLayout>
	)
}

const MapStateToProps = state =>({
	blog_list_category: state.Blog.blog_list_category,
	blog_list: state.Blog.blog_list,
	categories: state.Categories.categories,
	count: state.Blog.count,
    next: state.Blog.next,
    previous: state.Blog.previous,
})

export default connect(MapStateToProps,{
	get_blog_list,
	get_blog_list_category,
	get_blog_list_page,
	search_blog,
	get_categories,
})(BlogCategory)