import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { get_categories } from '../../redux/actions/categories';
import { BlogCard } from '../../components/blog/BlogCard';
import '../../styles/blogs.css';
import { PiMagnifyingGlass } from "react-icons/pi";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaTelegramPlane, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';



const WrapperBlog = ({
	blog_list,
	filtered_posts,
	get_categories,
	categories,
	onSubmit,
	goToSearch,
	onChange,
	search,
}) => {	

	const params = useParams();
	const categoryId = params.categoryId;
	const [limitedBlogs, setLimitedBlogs] = useState([]);
	const searchFormRef = useRef();

	useEffect(() => {
        if (blog_list) {
            setLimitedBlogs(blog_list.slice(0, 5));
        }
    }, [blog_list]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit(e);
	}

	return (
		<>
			<div className='container-blog-profile'>
				<h3 className='blog-wrapper-titles'>Sobre Mí</h3>
				<div className='container-blog-profile-img'>
					<img src={require(`../../images/mini-about.jpeg`)} />
				</div>
				<p className='text-about-wrapper'>¡Bienvenidos a mi rincón virtual! Soy Brenda, una apasionada de la cocina que descubrió este arte en su 
				nuevo hogar. Cada receta que preparo es un acto de amor y creatividad que me encanta compartir. Además, los gatos son mis compañeros 
				inseparables, llenando mi vida de ternura y diversión. Me fascina el mundo de las ventas, donde me desafío a crecer constantemente. 
				Pero sobre todo, mi fe en Dios guía cada paso que doy. ¡Exploremos juntos cada rincón de este sitio web!</p>
				<Link to='/about' className='btn btn-see-more' style={{textDecoration: 'none'}}>Sobre Mí</Link>
			</div>
			<div className='container-blog-socialmedia'>
				<h3 className='blog-wrapper-titles'>Mis Redes</h3>
				<div className='contain-blog-socialmedia'>
					<div className='container-blog-social-links'>
						<Link to='https://www.facebook.com/brenda.ledesma.986?locale=es_LA' className='blog-social-media facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
						<Link to='https://www.instagram.com/brenledesmats/' className='blog-social-media instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
						<Link to='https://www.pinterest.es/bledesma0111/' className='blog-social-media pinterest' style={{textDecoration: 'none'}}><FaPinterest /></Link>
						<Link to='/' className='blog-social-media telegram' style={{textDecoration: 'none'}}><FaTelegramPlane /></Link>
						<Link to='/' className='blog-social-media youtube' style={{textDecoration: 'none'}}><FaYoutube /></Link>
					</div>
				</div>
			</div>
			<form className='container-blog-search' onSubmit={e => onSubmit(e)} ref={searchFormRef}>
				<input 
					type='text'
					className='' 
					required
					onChange={e => onChange(e)}
					value={search}
				/>
				<span>Buscar</span>
				<i></i>
				<button className='icon-search-blog'><PiMagnifyingGlass /></button>
			</form>
			<div className='container-blog-categories'>
				<h3 className='blog-wrapper-titles'>Categorías</h3>
				<div className='contain-blog-categories'>
                    {
		                categories &&
		                categories !== null &&
		                categories !== undefined &&
		                categories.map(category => (
                            <div key={category.id} className='contain-blog-categories'>
                                <Link to={`/blogs/category/${category.id}`} style={{textDecoration: 'none'}} className='blog-category'>
                                   {category.name}
                                </Link>
                            </div>
                        ))
                    }
				</div>
			</div>
			<div className='container-most-recents'>
				<h3 className='blog-wrapper-titles'>Recientes</h3>
				{limitedBlogs.map((blog, index) => (
					<Link to={`/blogs/${blog.slug}`} style={{textDecoration: 'none'}} className='contain-recents-blog'>
						<div className='image-recents-blog'>
							<img src={blog && blog.thumbnail} />
						</div>
						<div className='wrapper-recent-blog-info'>
							<h4>{blog && blog.title}</h4>
						</div>
					</Link>
				))}
			</div>
			{/* <div className='container-most-popular'>
				<h3 className='blog-wrapper-titles'>Más Populares</h3>
				<Link to='/blogs' style={{textDecoration: 'none'}} className='contain-popular-blog'>
					<div className='image-banner-blog'>
						<img src={require(`../../images/banner.png`)} />
					</div>
					<div>
						<h4>Titulo del blog</h4>
						<div className='date-and-rating'>
							<p>Fecha</p>
							<p>Rating</p>
						</div>
					</div>
				</Link>
				<Link to='/blogs' style={{textDecoration: 'none'}} className='contain-popular-blog'>
					<div className='image-banner-blog'>
						<img src={require(`../../images/banner.png`)} />
					</div>
					<div>
						<h4>Titulo del blog</h4>
						<div className='date-and-rating'>
							<p>Fecha</p>
							<p>Rating</p>
						</div>
					</div>
				</Link>
				<Link to='/blogs' style={{textDecoration: 'none'}} className='contain-popular-blog'>
					<div className='image-banner-blog'>
						<img src={require(`../../images/banner.png`)} />
					</div>
					<div>
						<h4>Titulo del blog</h4>
						<div className='date-and-rating'>
							<p>Fecha</p>
							<p>Rating</p>
						</div>
					</div>
				</Link>
			</div> */}
		</>
	)
}

export default WrapperBlog