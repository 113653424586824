/* Utilities */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
/* Static pages */
import Home from './containers/pages/Home';
import About from './containers/pages/About';
import Contact from './containers/pages/Contact';
import Error404 from './containers/errors/Error404';
/* Auth */
import Activate from './containers/auth/Activate';
import Login from './containers/auth/Login';
import Signup from './containers/auth/Signup';
import SignupSuccess from './containers/auth/SignupSuccess';
import ResetPassword from './containers/auth/ResetPassword';
import ResetPasswordConfirm from './containers/auth/ResetPasswordConfirm';
/* Policies */
import CookiesPolicies from './containers/policies/CookiesPolicies';
import PrivacyPolicies from './containers/policies/PrivacyPolicies';
import PurchasingPolicies from './containers/policies/PurchasingPolicies';
/* Blogs */
import Blogs from './containers/blog/Blogs';
import BlogDetail from './containers/blog/BlogDetail';
import BlogCategory from './containers/blog/BlogCategory';
import BlogSearch from './containers/blog/BlogSearch';
/* Essen */
import Essen from './containers/essen/Essen';
import Products from './containers/essen/Products';
import ProductDetail from './containers/essen/ProductDetail';
import MyTeam from './containers/essen/MyTeam';
import Promotions from './containers/essen/Promotions';
/* Recipes */
import Recipes from './containers/recipes/Recipes';
import RecipeDetail from './containers/recipes/RecipeDetail';
/* Dashboard */
import Dashboard from './containers/pages/Dashboard';
import EditProfile from './containers/pages/EditProfile';
import WishBlogs from './containers/wishlist/WishBlogs';
import WishProducts from './containers/wishlist/WishProducts';
import WishRecipes from './containers/wishlist/WishRecipes';



function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    {/* Error Display */}
                    <Route path='*' element={<Error404 />} />

                    {/* Authentication */}
                    <Route exact path='/activate/:uid/:token' element={<Activate />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/register' element={<Signup />} />
                    <Route exact path='/signup-success' element={<SignupSuccess />} />
                    <Route exact path='/reset_password' element={<ResetPassword />} />
                    <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />

                    {/* Home Display */}
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/blogs' element={<Blogs />} />
                    <Route exact path='/blogs/:slug' element={<BlogDetail />} />
                    <Route exact path='/blogs/category/:categoryId' element={<BlogCategory />} />
                    <Route exact path='/blogs/search' element={<BlogSearch />} />
                    <Route exact path='/about' element={<About />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/dashboard' element={<Dashboard />} />
                    <Route exact path='/edit_profile' element={<EditProfile />} />

                    {/* Essen */}
                    <Route exact path='/essen' element={<Essen />} />
                    <Route exact path='/essen/promotions' element={<Promotions />} />
                    <Route exact path='/essen/my-team' element={<MyTeam />} />
                    <Route exact path='/products' element={<Products />} />
                    <Route exact path='/products/:productId' element={<ProductDetail />} />

                    {/* Recipes */}
                    <Route exact path='/recipes' element={<Recipes />} />
                    <Route exact path='/recipes/:recipeId' element={<RecipeDetail />} />
                    <Route exact path='/dashboard/wishlist/blogs' element={<WishBlogs />} />
                    <Route exact path='/dashboard/wishlist/products' element={<WishProducts />} />
                    <Route exact path='/dashboard/wishlist/recipes' element={<WishRecipes />} />

                    {/* Policies */}
                    <Route exact path='/cookies-policies' element={<CookiesPolicies />} />
                    <Route exact path='/privacy-policies' element={<PrivacyPolicies />} />
                    <Route exact path='/purchasing-policies' element={<PurchasingPolicies />} />
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;