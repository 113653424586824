import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import '../../styles/static-pages.css';
import { useEffect } from 'react';
import { ContactForm } from '../../components/aditional/ContactForm';


function Contact(){

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<FullWidthLayout>
			<div className='container-shop-title'>
				<h3>Contacto</h3>
			</div>
			<div className='container-contact-page'>
				<ContactForm />
			</div>
			<div></div>
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({

})

export default connect(MapStateToProps,{

})(Contact)