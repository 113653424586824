import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { PiTimer, PiUsersThree } from "react-icons/pi";


export const BlogCard = ({ 
	blog,
}) => {

	const [datePublishBlog, setDatePublishBlog] = useState(null);

	function getMonthName(monthNumber) {
		const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
		return monthNames[monthNumber];
	}

	function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getUTCDate();
		const month = date.getUTCMonth();
		const year = date.getUTCFullYear().toString().slice(-2);

		const monthName = getMonthName(month);
		return `${day} ${monthName} ${year}`;
	}

	useEffect(() => {
		if (blog.published) {
			setDatePublishBlog(formatDate(blog.published))
		}
	}, [blog]);

	return (
		<div className='container-blog-card'>
			<div className='blog-card-image'>
				<Link to={`/blogs/${blog && blog.slug}`}><img src={blog && blog.thumbnail} /></Link>
			</div>
			<div className='blog-card-info'>
				<Link to={`/blogs/${blog && blog.slug}`} className='link-to-post' style={{textDecoration: 'none'}}><h4 className='blog-card-title'>{blog.title}</h4></Link>
			</div>
			<div className='blog-card-excerpt'>
				<p className='blog-excerpt-text'>{blog && blog.excerpt}</p>

			</div>
			<div className='blog-card-date'>
				<p className='blog-date-text'>{datePublishBlog}</p>
			</div>
		</div>
	)
};