import {
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_BY_ARRIVAL_SUCCESS,
	GET_PRODUCTS_BY_ARRIVAL_FAIL,
	GET_PRODUCTS_BY_TYPE_SUCCESS,
	GET_PRODUCTS_BY_TYPE_FAIL,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	SEARCH_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS_FAIL,
	CATEGORY_PRODUCTS_SUCCESS,
	CATEGORY_PRODUCTS_FAIL,
	GET_PROMOS_SUCCESS,
	GET_PROMOS_FAIL,
} from './types'
import axios from 'axios'


export const get_products = (page = 1, order = 'desc', sortBy = 'date_created') => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/get-products?page=${page}&sortBy=${sortBy}&order=${order}`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_FAIL
		});
	}
};

export const get_products_by_type = () => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/get-products?sortBy=product_type&order=desc&limit=10`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCTS_BY_TYPE_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCTS_BY_TYPE_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCTS_BY_TYPE_FAIL
		});
	}
};

export const get_product = (productId) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/product/${productId}`, config);
	
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCT_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PRODUCT_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PRODUCT_FAIL
		});
	}
};

export const get_search_products = (search) => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({
		search
	});

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/search`, body, config);

		if (res.status === 200) {
			dispatch({
				type: SEARCH_PRODUCTS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: SEARCH_PRODUCTS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: SEARCH_PRODUCTS_FAIL
		});
	}
};

export const get_category_products = (category_id, page = 1) => async dispatch => {
	const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({
        category_id
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product/by/category?page=${page}&sortBy=date_created&order=desc`, body, config);
	
		if (res.status === 200 && !res.data.error) {
            dispatch({
                type: CATEGORY_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: CATEGORY_PRODUCTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: CATEGORY_PRODUCTS_FAIL
        });
    }
}

export const get_promo = () => async dispatch => {
	const config = {
		headers: {
			'Accept': 'application/json'
		}
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/promo`, config);

		if (res.status === 200) {
			dispatch({
				type: GET_PROMOS_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_PROMOS_FAIL
			});
		}
	} catch(err) {
		dispatch({
			type: GET_PROMOS_FAIL
		});
	}
};