import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import CardSwiper from '../../components/aditional/CardSwiper';
import { useEffect, useState } from 'react';
import { ContactForm } from '../../components/aditional/ContactForm'
import ParallaxSection from '../../components/aditional/ParallaxSection';
import { get_blog_list } from '../../redux/actions/blog';


function Home({
	get_blog_list,
	blog_list,
}){

	const [limitedBlogs, setLimitedBlogs] = useState([]);

	useEffect(() => {
		window.scrollTo(0,0)
		get_blog_list();
	}, []);

	useEffect(() => {
        if (blog_list) {
            setLimitedBlogs(blog_list.slice(0, 3));
        }
    }, [blog_list]);

	return (
		<FullWidthLayout>
			<div className='container-main-banner'>
			</div>
			<div className='container-main-lines'>
				<h2 className='main-essen-title'><span>Descubrí los productos </span>que harán que tu tiempo en la cocina sea más fácil y divertido</h2>
				<div className='container-essen-lines'>
					<div className='contain-essen-lines'>
						<h4>Línea contemporánea Capri</h4>
						<div className='contain-essen-image'>
							<img src={require(`../../images/linea-capri.jpg`)} />
						</div>
						<Link to='/products' className='btn btn-see-more' style={{textDecoration: 'none'}} >Ver más</Link>
					</div>
					<div className='contain-essen-lines'>
						<h4>Línea contemporánea Cherry</h4>
						<div className='contain-essen-image'>
							<img src={require(`../../images/linea-cherry.jpg`)} />
						</div>
						<Link to='/products' className='btn btn-see-more' style={{textDecoration: 'none'}} >Ver más</Link>
					</div>
					<div className='contain-essen-lines'>
						<h4>Línea contemporánea Terra</h4>
						<div className='contain-essen-image'>
							<img src={require(`../../images/linea-terra.jpg`)} />
						</div>
						<Link to='/products' className='btn btn-see-more' style={{textDecoration: 'none'}} >Ver más</Link>
					</div>
					<div className='contain-essen-lines'>
						<h4>Línea contemporánea Nuit</h4>
						<div className='contain-essen-image'>
							<img src={require(`../../images/linea-nuit.jpg`)} />
						</div>
						<Link to='/products' className='btn btn-see-more' style={{textDecoration: 'none'}} >Ver más</Link>
					</div>
				</div>
			</div>
			<div className='container-caracteristics'>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/entrega.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Envíos a todo el país</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/mediosdepago.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Todos los medios de pago</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/servicio-al-cliente.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Atención personalizada</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/descuentos.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Descuentos y promociones</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/trabajo-en-equipo.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Sumate a mi equipo</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/plan-canje.png`)} />
					</div>
					<div className='caracteristics-item-text'>
						<p>Plan canje</p>
					</div>
				</div>
			</div>
			<div className='main-essen-container'>
				<div className='contain-essen-link'>
					<Link to='/essen/promotions' className='link-to-essen' style={{textDecoration: 'none'}}>Últimas promociones</Link>
				</div>
			</div>
			<ParallaxSection className='main-recipes-container'>
				<div className='contain-recipes-info'>
					<h3 className='main-recipe-title'>Tenemos las mejores recetas para vos</h3>
					<p className='main-recipe-text'>¿Te quedas sin ideas para cocinar?</p>
					<p className='main-recipe-text'>Disfruta de nuestro catálogo completo de recetas</p>
					<Link to='/recipes' className='btn btn-see-recipes' style={{textDecoration: 'none'}}>Ver recetas</Link>
				</div>
				<div className='container-card-swiper'>
					<div className='contain-card-swiper'>
						<CardSwiper />
					</div>
				</div>
			</ParallaxSection>
			<div className='container-main-blog'>
				<div className='contain-blog-info'>
					<h3 className='blog-info-title'>Bienvenidos nuevos lectores</h3>
					<div className='blog-info-texts'>
						<p>Es con gran alegría y emoción que les doy la bienvenida a mi blog. Quiero compartir con ustedes mis motivaciones 
						para crear este espacio, que nace del profundo deseo de apoyar y enriquecer nuestras vidas familiares y espirituales.</p>
					</div>
				</div>
			</div>
			<div className='container-featured-blogs'>
				<h3>Ultimos blogs</h3>
				<div className='container-blogs-listed'>
					{limitedBlogs.map((blog, index) => (
                    	<div className='featured-blog' key={index}>
                        	<div className={`colored-featured-${index}`}>
                            	<h4 className='featured-blog-title'>{blog.title}</h4>
                            	<div className='featured-blog-image'>
                                	<img src={blog.thumbnail} alt={`Imagen de ${blog.title}`} />
                            	</div>
                        	</div>
                        	<Link className='btn btn-see-more' to={`/blogs/${blog.slug}`} style={{ textDecoration: 'none' }}>
                            	Leer
                        	</Link>
                    	</div>
                	))}
				</div>
			</div>
			<div className='container-contact-main'>
				<ContactForm />
			</div>
			<WhatsAppButton />
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({
	blog_list: state.Blog.blog_list,
})

export default connect(MapStateToProps, {
	get_blog_list,
})(Home)