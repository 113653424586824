import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';


function MyTeam(){
	return (
		<FullWidthLayout>
			MyTeam
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({

})

export default connect(MapStateToProps,{

})(MyTeam)