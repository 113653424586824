import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { Link } from 'react-router-dom';


function Error404(){
	return (
		<FullWidthLayout>
			<div className='container-error404'>
				<p>¡UPS! Ha ocurrido un error dirigiendote a la página</p>
				<div className='container-error404-image'><img src={require(`../../images/404-not-found.png`)} /></div>
				<p>Haz <Link to='/' className='required-fields-link' style={{textDecoration: 'none'}}>Click Aqui</Link> para regresar a inicio</p>
			</div>
		</FullWidthLayout>
	);
};

export default Error404