import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { connect } from 'react-redux';
import { useEffect } from 'react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import '../../styles/components.css';

// import required modules
import { EffectCards } from 'swiper/modules';
import { RecipeCardSwiper } from './RecipeCardSwiper';
import { get_recipes } from '../../redux/actions/recipes';

const CardSwiper = ({
    recipes,
    get_recipes,
}) => {

    const [limitedRecipes, setLimitedRecipes] = useState([])

    useEffect(() => {
        get_recipes()
    }, [get_recipes]);

    useEffect(() => {
        if (recipes) {
            setLimitedRecipes(recipes.slice(0, 10));
        }
    }, [recipes]);
    

    return (
        <>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
            >
                {limitedRecipes.map((recipe, index) => (
                    <SwiperSlide>
                        <RecipeCardSwiper item={recipe} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

const MapStateToProps = state =>({
    recipes: state.Recipes.recipes,
})

export default connect(MapStateToProps, {
    get_recipes,
})(CardSwiper)