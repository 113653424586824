// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';	
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Categories
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAIL = 'GET_PRODUCT_CATEGORIES_FAIL';

// Blog
export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAIL = 'GET_BLOG_LIST_FAIL';
export const GET_BLOG_LIST_CATEGORIES_SUCCESS = 'GET_BLOG_LIST_CATEGORIES_SUCCESS';
export const GET_BLOG_LIST_CATEGORIES_FAIL = 'GET_BLOG_LIST_CATEGORIES_FAIL';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';
export const GET_BLOG_PAGINATION_RESULTS_SUCCESS = 'GET_BLOG_PAGINATION_RESULTS_SUCCESS';
export const GET_BLOG_PAGINATION_RESULTS_FAIL = 'GET_BLOG_PAGINATION_RESULTS_FAIL';
export const GET_SEARCH_BLOG_SUCCESS = 'GET_SEARCH_BLOG_SUCCESS';
export const GET_SEARCH_BLOG_FAIL = 'GET_SEARCH_BLOG_FAIL';

// Products
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_PRODUCTS_BY_ARRIVAL_SUCCESS = 'GET_PRODUCTS_BY_ARRIVAL_SUCCESS';
export const GET_PRODUCTS_BY_ARRIVAL_FAIL = 'GET_PRODUCTS_BY_ARRIVAL_FAIL';
export const GET_PRODUCTS_BY_TYPE_SUCCESS = 'GET_PRODUCTS_BY_TYPE_SUCCESS';
export const GET_PRODUCTS_BY_TYPE_FAIL = 'GET_PRODUCTS_BY_TYPE_FAIL';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAIL = 'SEARCH_PRODUCTS_FAIL';
export const CATEGORY_PRODUCTS_SUCCESS = 'CATEGORY_PRODUCTS_SUCCESS';
export const CATEGORY_PRODUCTS_FAIL = 'CATEGORY_PRODUCTS_FAIL';
export const GET_PROMOS_SUCCESS = 'GET_PROMOS_SUCCESS';
export const GET_PROMOS_FAIL = 'GET_PROMOS_FAIL';

// Recipes
export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS';
export const GET_RECIPE_FAIL = 'GET_RECIPE_FAIL';
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS';
export const GET_RECIPES_FAIL = 'GET_RECIPES_FAIL';
export const GET_PRODUCTS_USED_SUCCESS = 'GET_PRODUCTS_USED_SUCCESS';
export const GET_PRODUCTS_USED_FAIL = 'GET_PRODUCTS_USED_FAIL';
export const SEARCH_RECIPES_SUCCESS = 'SEARCH_RECIPES_SUCCESS';
export const SEARCH_RECIPES_FAIL = 'SEARCH_RECIPES_FAIL';

// Wishlist
export const GET_WISHLIST_ITEMS_SUCCESS = 'GET_WISHLIST_ITEMS_SUCCESS';
export const GET_WISHLIST_ITEMS_FAIL = 'GET_WISHLIST_ITEMS_FAIL';
export const ADD_WISHLIST_ITEM_SUCCESS = 'ADD_WISHLIST_ITEM_SUCCESS';
export const ADD_WISHLIST_ITEM_FAIL = 'ADD_WISHLIST_ITEM_FAIL';
export const GET_WISHLIST_ITEM_TOTAL_SUCCESS = 'GET_WISHLIST_ITEM_TOTAL_SUCCESS';
export const GET_WISHLIST_ITEM_TOTAL_FAIL = 'GET_WISHLIST_ITEM_TOTAL_FAIL';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_FAIL = 'REMOVE_WISHLIST_ITEM_FAIL';
export const CLEAR_WISHLIST = 'CLEAR_WISHLIST';
export const GET_WISHLIST_RECIPE_ITEMS_SUCCESS = 'GET_WISHLIST_RECIPE_ITEMS_SUCCESS';
export const GET_WISHLIST_RECIPE_ITEMS_FAIL = 'GET_WISHLIST_RECIPE_ITEMS_FAIL';
export const ADD_WISHLIST_RECIPE_ITEM_SUCCESS = 'ADD_WISHLIST_RECIPE_ITEM_SUCCESS';
export const ADD_WISHLIST_RECIPE_ITEM_FAIL = 'ADD_WISHLIST_RECIPE_ITEM_FAIL';
export const GET_WISHLIST_RECIPE_ITEM_TOTAL_SUCCESS = 'GET_WISHLIST_RECIPE_ITEM_TOTAL_SUCCESS';
export const GET_WISHLIST_RECIPE_ITEM_TOTAL_FAIL = 'GET_WISHLIST_RECIPE_ITEM_TOTAL_FAIL';
export const REMOVE_WISHLIST_RECIPE_ITEM_SUCCESS = 'REMOVE_WISHLIST_RECIPE_ITEM_SUCCESS';
export const REMOVE_WISHLIST_RECIPE_ITEM_FAIL = 'REMOVE_WISHLIST_RECIPE_ITEM_FAIL';
export const CLEAR_WISHLIST_RECIPE = 'CLEAR_WISHLIST_RECIPE';
export const GET_WISHLIST_BLOG_ITEMS_SUCCESS = 'GET_WISHLIST_BLOG_ITEMS_SUCCESS';
export const GET_WISHLIST_BLOG_ITEMS_FAIL = 'GET_WISHLIST_BLOG_ITEMS_FAIL';
export const ADD_WISHLIST_BLOG_ITEM_SUCCESS = 'ADD_WISHLIST_BLOG_ITEM_SUCCESS';
export const ADD_WISHLIST_BLOG_ITEM_FAIL = 'ADD_WISHLIST_BLOG_ITEM_FAIL';
export const GET_WISHLIST_BLOG_ITEM_TOTAL_SUCCESS = 'GET_WISHLIST_BLOG_ITEM_TOTAL_SUCCESS';
export const GET_WISHLIST_BLOG_ITEM_TOTAL_FAIL = 'GET_WISHLIST_BLOG_ITEM_TOTAL_FAIL';
export const REMOVE_WISHLIST_BLOG_ITEM_SUCCESS = 'REMOVE_WISHLIST_BLOG_ITEM_SUCCESS';
export const REMOVE_WISHLIST_BLOG_ITEM_FAIL = 'REMOVE_WISHLIST_BLOG_ITEM_FAIL';
export const CLEAR_WISHLIST_BLOG = 'CLEAR_WISHLIST_BLOG';

// Reviews
export const GET_REVIEWS_RECIPE_SUCCESS = 'GET_REVIEWS_RECIPE_SUCCESS';
export const GET_REVIEWS_RECIPE_FAIL = 'GET_REVIEWS_RECIPE_FAIL';
export const GET_REVIEW_RECIPE_SUCCESS = 'GET_REVIEW_RECIPE_SUCCESS';
export const GET_REVIEW_RECIPE_FAIL = 'GET_REVIEW_RECIPE_FAIL';
export const CREATE_REVIEW_RECIPE_SUCCESS = 'CREATE_REVIEW_RECIPE_SUCCESS';
export const CREATE_REVIEW_RECIPE_FAIL = 'CREATE_REVIEW_RECIPE_FAIL';
export const UPDATE_REVIEW_RECIPE_SUCCESS = 'UPDATE_REVIEW_RECIPE_SUCCESS';
export const UPDATE_REVIEW_RECIPE_FAIL = 'UPDATE_REVIEW_RECIPE_FAIL';
export const DELETE_REVIEW_RECIPE_SUCCESS = 'DELETE_REVIEW_RECIPE_SUCCESS';
export const DELETE_REVIEW_RECIPE_FAIL = 'DELETE_REVIEW_RECIPE_FAIL';
export const FILTER_REVIEWS_RECIPE_SUCCESS = 'FILTER_REVIEWS_RECIPE_SUCCESS';
export const FILTER_REVIEWS_RECIPE_FAIL = 'FILTER_REVIEWS_RECIPE_FAIL';
export const GET_REVIEWS_BLOG_SUCCESS = 'GET_REVIEWS_BLOG_SUCCESS';
export const GET_REVIEWS_BLOG_FAIL = 'GET_REVIEWS_BLOG_FAIL';
export const GET_REVIEW_BLOG_SUCCESS = 'GET_REVIEW_BLOG_SUCCESS';
export const GET_REVIEW_BLOG_FAIL = 'GET_REVIEW_BLOG_FAIL';
export const CREATE_REVIEW_BLOG_SUCCESS = 'CREATE_REVIEW_BLOG_SUCCESS';
export const CREATE_REVIEW_BLOG_FAIL = 'CREATE_REVIEW_BLOG_FAIL';
export const UPDATE_REVIEW_BLOG_SUCCESS = 'UPDATE_REVIEW_BLOG_SUCCESS';
export const UPDATE_REVIEW_BLOG_FAIL = 'UPDATE_REVIEW_BLOG_FAIL';
export const DELETE_REVIEW_BLOG_SUCCESS = 'DELETE_REVIEW_BLOG_SUCCESS';
export const DELETE_REVIEW_BLOG_FAIL = 'DELETE_REVIEW_BLOG_FAIL';
export const FILTER_REVIEWS_BLOG_SUCCESS = 'FILTER_REVIEW_BLOG_SUCCESS';
export const FILTER_REVIEWS_BLOG_FAIL = 'FILTER_REVIEWS_BLOG_FAIL';

// Profile
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const REMOVE_PROFILE_LOADING = 'REMOVE_PROFILE_LOADING';