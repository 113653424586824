import '../../styles/cards.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PiTimer, PiUsersThree } from "react-icons/pi";
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";


export const RecipeCardSwiper = ({ 
	item,
	remove_wishlist_item,
	render,
	setRender,
}) => {

	const removeItemHandler = async () => {
		await remove_wishlist_item(item.product.id);
		setRender(!render);
	}

	return (
		<div className='container-swiper-card'>
			<div className='container-swiper-recipe'>
				<div className='swiper-recipe-image'>
					<img src={item.photo} />
				</div>
				<div className='swiper-item-info'>
					<div className='swiper-recipe-info'>
						<p>{item.name}</p>
					</div>
					<div className='swiper-recipe-details'>
						<p className='card-details-text-swiper'><PiUsersThree className='' />{item.dinners}</p>
						<p className='card-details-text-swiper'><PiTimer className='' />{item.duration} min</p>
					</div>
					<Link to={`/recipes/${item.id}`} className='btn-go-recipe' style={{textDecoration: 'none'}}><FaAnglesRight className='icons-to-recipe' />Hazla ahora<FaAnglesLeft className='icons-to-recipe' /></Link>
				</div>
			</div>
		</div>
	)
};