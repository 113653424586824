import {
	GET_RECIPE_SUCCESS,
	GET_RECIPE_FAIL,
	GET_RECIPES_SUCCESS,
	GET_RECIPES_FAIL,
	GET_PRODUCTS_USED_SUCCESS,
	GET_PRODUCTS_USED_FAIL,
	SEARCH_RECIPES_SUCCESS,
	SEARCH_RECIPES_FAIL,
} from '../actions/types';


const initialState = {
	recipes: null,
	recipe: null,
	products_used: null,
	search_recipes: null,
	count: 0,
    next: null,
    previous: null,
}

export default function Recipes(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case GET_RECIPE_SUCCESS:
			return {
				...state,
				recipe: payload.recipe
			}
		case GET_RECIPE_FAIL:
			return {
				...state,
				recipe: null
			}
		case GET_RECIPES_SUCCESS:
			return {
				...state,
				recipes: action.payload.results,
				count: action.payload.count,
				next: action.payload.next,
				previous: action.payload.previous,
			}
		case GET_RECIPES_FAIL:
			return {
				...state,
				recipes: [],
				count: 0,
				next: null,
				previous: null,
			}
		case GET_PRODUCTS_USED_SUCCESS:
			return {
				...state,
				products_used: payload.products_used
			}
		case GET_PRODUCTS_USED_FAIL:
			return {
				...state,
				products_used: null
			}
		case SEARCH_RECIPES_SUCCESS:
			return {
				...state,
				search_recipes: payload.search_recipes
			}
		case SEARCH_RECIPES_FAIL:
			return {
				...state,
				search_recipes: null
			}
		default:
			return state
	}
}