import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { get_recipes, get_search_recipes } from '../../redux/actions/recipes';
import { RecipeCard } from '../../components/recipes/RecipeCard';
import { PiMagnifyingGlass } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";


const Recipes = ({
	get_recipes,
	get_search_recipes,
	recipes,
	search_recipes,
	count,
	next,
	previous,
}) => {

	const params = useParams();
	const categoryName = params.categoryName
	const [searchRecipe, setSearchRecipe] = useState('');
	const searchFormRef = useRef();
	const recipesState = useSelector(state => state.Recipes);
	const [page, setPage] = useState(1);

	useEffect(() => {
		window.scrollTo(0,0)
		get_recipes(page)
	}, []);

	useEffect(() => {
		window.scrollTo(0,0)
		get_recipes(page);
	}, [page]);

	const onChange = e => setSearchRecipe(e.target.value)

	const onSubmit = e => {
		e.preventDefault()
		get_search_recipes(searchRecipe);
        searchFormRef.current.reset();
        setSearchRecipe('');
	}

	const showRecipes = () => {
		let display = []

		const itemsToDisplay = search_recipes && search_recipes.length > 0 ? search_recipes : recipes;


		if (
		    itemsToDisplay &&
		    itemsToDisplay !== null && 
		    itemsToDisplay !== undefined
		) {
            itemsToDisplay.map((recipe, index) => {
                display.push(
                    <div key={index} className="product-card-wrapper">
                        <RecipeCard recipe={recipe} />
                    </div>
                );
            });
        }

        return display;
	};

	const handleNextPage = () => {
        if (recipesState.next) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (recipesState.previous && page > 1) {
            setPage(page - 1);
        }
    };

	return (
		<FullWidthLayout>
			<div className='container-recipes-title'>
				<h3>Recetas</h3>
			</div>
			<div className='container-recipe-search'>
				<form className='input-search-recipe' onSubmit={e => onSubmit(e)} ref={searchFormRef}>
					<input 
						type='text'
						className='' 
						required
						onChange={e => onChange(e)}
					/>
					<span>Buscar recetas</span>
					<i></i>
					<button className='icon-search-blog'><PiMagnifyingGlass /></button>
				</form>
			</div>
			<div className='container-recipe-pagination'>
				<div className='container-recipe-items'>
					<div className='shop-recipes'>
						{showRecipes()}
					</div>
				</div>
				<div className="pagination-controls-recipe">
                	<button className='pagination-button' onClick={handlePreviousPage} disabled={!recipesState || (recipesState.previous === null && page === 1)}>
                   		<FaAngleLeft className='pagination-button-icon' />Anterior
                	</button>
                	<button className='pagination-button' onClick={handleNextPage} disabled={!recipesState || recipesState.next === null}>
                   		Siguiente <FaAngleRight className='pagination-button-icon' />
               		</button>
           		</div>
            </div>
		</FullWidthLayout>
	)
}

const MapStateToProps = state =>({
	recipes: state.Recipes.recipes,
	search_recipes: state.Recipes.search_recipes,
	count: state.Recipes.count,
	next: state.Recipes.next,
	previous: state.Recipes.previous,
})

export default connect(MapStateToProps,{
	get_recipes,
	get_search_recipes,
})(Recipes)