import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { reset_password_confirm } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Navigate } from 'react-router';


const ResetPasswordConfirm = ({
 	reset_password_confirm, loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const params = useParams()

	const [requestSent, setRequestSent] = useState(false);
	const [formData, setFormData] = useState({
		new_password: '',
		re_new_password: ''
	});


	const {
		new_password,
		re_new_password
	} = formData;


	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	const uid = params.uid
    	const token = params.token

    	reset_password_confirm(uid, token, new_password, re_new_password)
    	if (new_password === re_new_password) {
    		setRequestSent(true);
    	}
  	};

  	if (requestSent && !loading)
  		return <Navigate to='/' />;

	return (
		<FullWidthLayout>
			<div className='container-login'>
				<div className='container-reset-form'>
					<span className='border-line-2'></span>
					<form className='form-content' onSubmit={e => onSubmit(e)}>
						<div className='contain-form'>
							<h3 className='form-reset-title'>Escriba su nueva contraseña</h3>
							<div className='input-contain'>
								<input
									name='new_password'
									value={new_password}
									onChange={e => onChange(e)}
									type='password'
									required
									className=''
								/>
								<span htmlFor='password'>Nueva contraseña</span>
								<i></i>
							</div>
							<div className='input-contain'>
								<input
									name='re_new_password'
									value={re_new_password}
									onChange={e => onChange(e)}
									type='password'
									required
									className=''
								/>
								<span htmlFor='re_password'>Repita la nueva contraseña</span>
								<i></i>
							</div>
							{loading ? 
								<button type='button'><Oval
			  						visible={true}
									height="20"
									width="20"
									color="#222"
									ariaLabel="oval-loading"
									wrapperStyle={{}}
									wrapperClass=""
									className='button-form'
									secondaryColor="#ddd"
									/>
								</button> : 
								<button type='submit' className='button-form'>
									Cambiar contraseña
								</button>
							}
						</div>
					</form>
				</div>
			</div>
		</FullWidthLayout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	reset_password_confirm 
}) (ResetPasswordConfirm)