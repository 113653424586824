import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/essen.css';


function Essen(){

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<FullWidthLayout>
			<div className='container-essen-promotions'>
				<h3>Nuestras ultimas promociones</h3>
				<p>No te pierdas las ofertas y beneficios del ciclo</p>
				<Link to='/essen/promotions' className='btn2 btn-see-recipes' style={{textDecoration: 'none'}}>Aprovechalas</Link>
			</div>
			<div className='container-essen-products'>
				<div className='essen-products-img'>
					<img src={require(`../../images/fondo-productos.png`)} />
				</div>
				<div className='contain-essen-products'>
					<h3>Los mejores productos son</h3>
					<img src={require(`../../images/logoessen.png`)} className='image-essen-logo' />
					<Link to='/products' className='btn btn-see-more' style={{textDecoration: 'none'}}>Ver ahora</Link>
				</div>
			</div>
			<div className='container-essen-plus'>
				<img src={require(`../../images/essen+-logo.png`)} />
				<div className='contain-essen-plus'>
					<h3>Llegó Essen+</h3>
					<p className='essen-plus-text'>El nuevo programa de beneficios para tener tu próxima <span>Essen GRATIS</span></p>
					<Link to='https://essen-mas.com/guest' className='btn2 btn-see-recipes' target='_blank' style={{textDecoration: 'none'}}>Registrate</Link>
				</div>
			</div>
			<div className='container-essen-fire'>
				<div className='contain-essen-fire'>
					<h3>¿Por qué elegir Essen?</h3>
					<p>Con nuestros productos podés preparar miles de recetas y en cada preparación, gracias a la distribución homogénea del calor, vas a ahorrar 
					tiempo, consumo de gas y energía.</p>
					<img src={require(`../../images/ahorro-gas.jpg`)} />
					<p>Además, cocinando con un producto Essen, en cada receta resaltás el sabor de los ingredientes y mantenes sus nutrientes.</p>
					<p>Nuestros productos están hechos con la mejor calidad, con esmalte vitro porcelanizado de alta resistencia y el mejor antiadherente. Essen 
					es diseño, innovación y calidad.</p>
				</div>
				<div className='container-essen-fire-boxes'>
					<h3>Conocé los tipos de fuego</h3>
					<div className='container-grid-animation'>
						<div className='animation-content'>
							<div className='animation-face face-1'>
								<div className='content'>
									<img src={require(`../../images/fuego1.png`)} className='img-fire' />
									<h4>Fuego Máximo</h4>
								</div>
							</div>
							<div className='animation-face face-2'>
								<div className='content'>
									<p>La llama abarca la base de la cacerola sin sobrepasarla</p>
								</div>
							</div>
						</div>
						<div className='animation-content'>
							<div className='animation-face face-1'>
								<div className='content'>
									<img src={require(`../../images/fuego2.png`)} className='img-fire' />
									<h4>Fuego Medio</h4>
								</div>
							</div>
							<div className='animation-face face-2'>
								<div className='content'>
									<p>La llama toca la base de la cacerola sin abarcarla por completo</p>
								</div>
							</div>
						</div>
						<div className='animation-content'>
							<div className='animation-face face-1'>
								<div className='content'>
									<img src={require(`../../images/fuego3.png`)} className='img-fire' />
									<h4>Fuego Minimo</h4>
								</div>
							</div>
							<div className='animation-face face-2'>
								<div className='content'>
									<p>La llama apenas toca la base de la cacerola</p>
								</div>
							</div>
						</div>
						<div className='animation-content'>
							<div className='animation-face face-1'>
								<div className='content'>
									<img src={require(`../../images/fuego4.png`)} className='img-fire' />
									<h4>Fuego Corona</h4>
								</div>
							</div>
							<div className='animation-face face-2'>
								<div className='content'>
									<p>La llama forma una corona que no supera la altura del mechero</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</FullWidthLayout>
	);
};

const MapStateToProps = state =>({

})

export default connect(MapStateToProps,{

})(Essen)