import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { FavoriteHeart } from '../aditional/FavoriteHeart';


export const ProductCard = ({ product, product_categories }) => {

	const getCategoryName = (categoryId) => {
		const category = product_categories.find(cat => cat.id === categoryId);
		return category ? category.name : 'N/A';
	}

	return (
		<div className='container-product-card'>
			<div className='product-card-image'>
				<Link to={`/products/${product.id}`}><img src={product && product.main_photo} /></Link>
				<div className='stocked-product'>
				</div>
			</div>
			<div className='product-card-category'>
				<p>{getCategoryName(product.category)}</p>
			</div>
			<div className='product-card-info'>
				<Link to={`/products/${product && product.id}`} style={{textDecoration: 'none'}}><h4 className={`product-card-title ${product && product.product_line}`}>{product.name}</h4></Link>
			</div>
		</div>
	)
};