import FullWidthLayout from '../../hocs/layouts/FullWidthLayout';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { get_blog_list, get_blog_list_page, search_blog } from '../../redux/actions/blog';
import { get_categories } from '../../redux/actions/categories';
import { BlogCard } from '../../components/blog/BlogCard';
import '../../styles/blogs.css';
import { PiMagnifyingGlass } from "react-icons/pi";
import { FaFacebook, FaInstagram, FaChevronDown, FaPinterest, FaEnvelope, FaChevronUp, FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import WrapperBlog from '../../components/blog/WrapperBlog';
import { Navigate } from 'react-router';


const BlogSearch = ({
	get_blog_list,
	get_blog_list_page,
	search_blog,
	blog_list,
	get_categories,
	categories,
	filtered_posts,
}) => {

	const params = useParams();
	const categoryId = params.categoryId
	const [categoryName, setCategoryName] = useState(null);
	const [search, setSearch] = useState('');
	const [goToSearch, setGoToSearch] = useState(false);

	useEffect(() => {
		window.scrollTo(0,0);
		get_blog_list();
		get_categories();
	}, []);
	
	useEffect(() => {
		if (categories) {
			const category = categories.find(category => (Number(category.id)) === (Number(categoryId)));

			if (category) {
				setCategoryName(category.name);
			} else {
				setCategoryName(null);
			}
		}
	}, [categories, categoryId]);

	const onChange = (e) => setSearch(e.target.value);

	const onSubmit = (e) => {
		e.preventDefault()
		search_blog(search);
        setSearch('');
        setGoToSearch(!goToSearch);
	}

	const showBlogs = () => {
				let results = []
				let display = []

				if (
		          filtered_posts &&
		          filtered_posts !== null && 
		          filtered_posts !== undefined
		      ) {
		          filtered_posts.map((blog, index) => {
		            	return display.push(
			                <div key={index}>
			                    <BlogCard 
			                    		blog={blog}
			                    />
			                </div>
		            	);
		        	});
		     }

				for (let i = 0; i < display.length; i += 3) {
					results.push(
						<div key={i} className='blogs-listed'>
							{display[i] ? display[i] : <div></div>}
							{display[i+1] ? display[i+1] : <div></div>}
							{display[i+2] ? display[i+2] : <div></div>}
						</div>
						)
					}
				return results;
		}

	return (
		<FullWidthLayout>
			<div className='container-blog-title'>
				<h3>Mis blogs</h3>
			</div>
			<div className='wrapper-blogs'>
				<div className='container-blogs'>
					<h3>Resultados de tu busqueda</h3>
					<div className='blog-listed'>
						{showBlogs()}
					</div>
				</div>
				<div className='container-right-wrapper'>
					< WrapperBlog
						blog_list={blog_list}
						categories={categories}
						search_blog={search_blog}
						onSubmit={onSubmit}
						onChange={onChange}
						search={search}
					/>
				</div>
			</div>
		</FullWidthLayout>
	)
}

const MapStateToProps = state =>({
	blog_list: state.Blog.blog_list,
	categories: state.Categories.categories,
	filtered_posts: state.Blog.filtered_posts,
})

export default connect(MapStateToProps,{
	get_blog_list,
	get_blog_list_page,
	search_blog,
	get_categories,
})(BlogSearch)