import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { Menu } from '@headlessui/react'
import { Navigate } from 'react-router';
import '../../styles/navigation.css';
import { FaSearch } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io";
import { get_wishlist_item_total } from '../../redux/actions/wishlist';
import { logout, check_authenticated } from '../../redux/actions/auth';
import { FaCaretDown, FaBars } from "react-icons/fa";


const CustomLink = ({to, title, className=''}) => {
	return (
		<NavLink to={to} className={`${className} ${isActive => isActive ? 'active' : ''}`} style={{textDecoration:'none'}}>
			{title}
			<span className='span-bar'></span>
		</NavLink>
	);
};

function Navbar({
	isAuthenticated,
	get_wishlist_item_total,
	check_authenticated,
}) {

	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		get_wishlist_item_total()
		check_authenticated()
	}, []);
	
	const authLinks = (
		<div className='navlink-social'>
			<CustomLink to='/dashboard' className='navlink-item' title='Perfil' />
		</div>          
	);

	const guestLinks = (
		<Fragment >
			<div className='navlink-social'>
				<CustomLink to='/login' className='navlink-item' title='Ingresar' />
			</div>
		</Fragment>
	);

	const toggleMenu = () => {
    	setMenuOpen(!menuOpen);
  	};

	return (
		<>
			<div className='container-navbar'>
				<Link to='/' style={{textDecoration: 'none'}} className='logo-position'>Brenda Ledesma</Link>
				<div className={`container-navlinks navbar ${menuOpen ? 'menu-active' : ''}`}>
					<CustomLink to='/' className='navlink-item' title='Inicio' />
					<CustomLink to='/blogs' className='navlink-item' title='Blogs' />
					<CustomLink to='/essen' className='navlink-item' title='Essen' />
					<CustomLink to='/products' className='navlink-item' title='Productos' />
					<CustomLink to='/recipes' className='navlink-item' title='Recetas' />
					<div>
						{ 
							isAuthenticated ? authLinks : guestLinks
						}
					</div>
				</div>
				<button className='nav-open-btn' id='menu-toggle' aria-label='Toggle Menu' onClick={toggleMenu}>
					<span className={`line ${menuOpen ? 'line-1' : ''}`}></span>
					<span className={`line ${menuOpen ? 'line-2' : ''}`}></span>
					<span className={`line ${menuOpen ? 'line-3' : ''}`}></span>
				</button>
			</div>
		</>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
	get_wishlist_item_total,
	check_authenticated,
}) (Navbar)